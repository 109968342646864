/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type PaginationResponse from "./PaginationResponse";
import type TestFarmerSampleResponse from "./TestFarmerSampleResponse";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type PendingSubmissionQuery from "./PendingSubmissionQuery";
import type SubmissionDataResponse from "./SubmissionDataResponse";
import type PrepareSubmissionFarmer from "./PrepareSubmissionFarmer";
import type DataRequest from "./DataRequest";
import type SubmissionDisplay from "./SubmissionDisplay";
import type Void from "./Void";
import type ManualFarmerSubmissionRequest from "./ManualFarmerSubmissionRequest";
import type ReportDataRequest from "./ReportDataRequest";
import type MfwSuccess from "./MfwSuccess";
import type IdRequest from "./IdRequest";
import type PreReportData from "./PreReportData";
import type SubmissionReportDataFarmResponseApi from "./SubmissionReportDataFarmResponseApi";
import type IdsRequest from "./IdsRequest";
import type ReportDataResultFarm from "./ReportDataResultFarm";
import type UpdateReportDataRequestFarm from "./UpdateReportDataRequestFarm";

const urls = {
    questPdf(submissionId: number): string {
        return queryUrl("/api/farmerssample/questpdf", {submissionId});
    }
}

export default {
    urls,
    notCompleted(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<TestFarmerSampleResponse>>> {
        return Axios.post("/api/farmerssample/notcompleted", request, defaultConfig);
    },
    archived(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<TestFarmerSampleResponse>>> {
        return Axios.post("/api/farmerssample/archived", request, defaultConfig);
    },
    completed(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<TestFarmerSampleResponse>>> {
        return Axios.post("/api/farmerssample/completed", request, defaultConfig);
    },
    pendingSubmissions(requestSearch: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<PendingSubmissionQuery>>> {
        return Axios.post("/api/farmerssample/pendingsubmissions", requestSearch, defaultConfig);
    },
    prepareSubmission(request: DataRequest): Promise<AxiosResponse<SubmissionDataResponse<PrepareSubmissionFarmer>>> {
        return Axios.post("/api/farmerssample/preparesubmission", request, defaultConfig);
    },
    pagedWaiting(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<SubmissionDisplay>>> {
        return Axios.post("/api/farmerssample/pagedwaiting", request, defaultConfig);
    },
    pagedCompleted(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<SubmissionDisplay>>> {
        return Axios.post("/api/farmerssample/pagedcompleted", request, defaultConfig);
    },
    pagedResults(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<SubmissionDisplay>>> {
        return Axios.post("/api/farmerssample/pagedresults", request, defaultConfig);
    },
    getSampleNumbersForSubmission(submissionId: number): Promise<AxiosResponse<string[]>> {
        return Axios.post("/api/farmerssample/getsamplenumbersforsubmission", submissionId, defaultConfig);
    },
    updateManualSubmission(request: ManualFarmerSubmissionRequest): Promise<AxiosResponse<Void>> {
        return Axios.post("/api/farmerssample/updatemanualsubmission", request, defaultConfig);
    },
    questPdf(submissionId: number): Promise<AxiosResponse> {
        return Axios.get(queryUrl("/api/farmerssample/questpdf", {submissionId}), defaultConfig);
    },
    submit(request: ReportDataRequest<PrepareSubmissionFarmer>): Promise<AxiosResponse<ReportDataRequest<PrepareSubmissionFarmer>>> {
        return Axios.post("/api/farmerssample/submit", request, defaultConfig);
    },
    postToMfw(request: IdRequest): Promise<AxiosResponse<MfwSuccess>> {
        return Axios.post("/api/farmerssample/posttomfw", request, defaultConfig);
    },
    preReport(request: IdsRequest): Promise<AxiosResponse<PreReportData<SubmissionReportDataFarmResponseApi>>> {
        return Axios.post("/api/farmerssample/prereport", request, defaultConfig);
    },
    updateReportData(reports: UpdateReportDataRequestFarm[]): Promise<AxiosResponse<ReportDataResultFarm[]>> {
        return Axios.post("/api/farmerssample/updatereportdata", reports, defaultConfig);
    }
}
