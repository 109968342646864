import React, {Fragment, useContext, useEffect, useState} from "react";
import AppContext from "./appContext";
import PermissionEnum from "./controllers/PermissionEnum";
import {classNames, wrapLoader} from "./wrapper";
import {Link, Outlet} from "react-router-dom";
import AuthController from "./controllers/AuthController";

const signalR = require('@microsoft/signalr')


interface SubMenuItem {
    id: string;
    display: string;
    permission: PermissionEnum;
    path: string;
}

interface MenuItem {
    name: string;
    subItems: SubMenuItem[]
}

const signal = new signalR.HubConnectionBuilder()
    .withUrl('/messages')
    .withAutomaticReconnect()
    .build()

const menuItems: MenuItem[] = [
    {
        name: 'ITest Leaf',
        subItems: [
            {id: 'leafs', display: 'Entries', permission: PermissionEnum.Leafs, path: '/leafs'},
            {id: 'submissions', display: 'Submissions', permission: PermissionEnum.Submissions, path: '/submissions'}
        ]
    },
    {
        name: 'ITest Carbohydrates',
        subItems: [
            {id: 'entries', display: 'Results', permission: PermissionEnum.CarbohydrateEntries, path: '/carbohydrates'},
            {
                id: 'tissues',
                display: 'Tissues',
                permission: PermissionEnum.CarbohydrateTissues,
                path: '/carbohydratestissues'
            },
            {
                id: 'growths',
                display: 'Growths',
                permission: PermissionEnum.CarbohydrateEntries,
                path: '/carbohydratesgrowths'
            },
        ]
    },
    {
        name: 'ITest Farmers Sample',
        subItems: [
            {
                id: 'entries', 
                display: 'Entries', 
                permission: PermissionEnum.FarmersSampleEntries, 
                path: '/farmerssampleentries'
            },
            {
                id: 'submissions',
                display: 'Submissions',
                permission: PermissionEnum.FarmersSampleSubmissions,
                path: '/farmerssamplesubmissions'
            },
        ]
    },
    {
        name: 'Lab',
        subItems: [
            {id: 'jobs', display: 'Jobs', permission: PermissionEnum.LabJobs, path: '/labjobs'},
        ]
    },
    {
        name: 'Configuration',
        subItems: [
            {id: 'labmin', display: 'Labmin', permission: PermissionEnum.Labmin, path: '/labmins'},
            {id: 'crops', display: 'Crop', permission: PermissionEnum.Crop, path: '/crops'},
            {id: 'drisgraphs', display: 'DrisGraphs', permission: PermissionEnum.DrisGraphs, path: '/drisgraphs'}
        ]
    },
    {
        name: 'Management',
        subItems: [
            {id: 'user', display: 'Users', permission: PermissionEnum.Users, path: '/users'},
            {id: 'roles', display: 'Roles', permission: PermissionEnum.Roles, path: '/roles'},
            {id: 'languages', display: 'Language', permission: PermissionEnum.Language, path: '/languages'},
            {id: 'address', display: 'Address', permission: PermissionEnum.Address, path: '/addresses'},
            {
                id: 'distributors',
                display: 'Distributors',
                permission: PermissionEnum.Distributors,
                path: '/distributors'
            },
            {id: 'farms', display: 'Farms', permission: PermissionEnum.Admin, path: '/farms'},
            {id: 'settings', display: 'Settings', permission: PermissionEnum.Settings, path: '/settings'},
            {id: 'agronomist', display: 'Agronomist', permission: PermissionEnum.Agronomists, path: '/agronomist'},
        ]
    }
];

const Menu: React.FC<{
    render: (item: SubMenuItem, active: boolean) => React.ReactNode;
    className?: string;
}> = (props) => {
    const context = useContext(AppContext)
    const [selected, setSelected] = useState('')


    function checkSubPermissions(subItems: MenuItem["subItems"]): boolean {
        return subItems.some(sub => context.hasPermission(sub.permission));
    }

    return (
        <div className={classNames('space-y-1', props.className ?? '')}>
            <nav>
                {menuItems.map(item => {
                    return <Fragment key={item.name}>

                        {checkSubPermissions(item.subItems) ?

                            <button onClick={() => setSelected(selected === item.name ? '' : item.name)} type="button"
                                    className="bg-gray-800 text-white hover:text-gray-200 group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none"
                                    aria-controls="sub-menu-1" aria-expanded="false">
                                <svg
                                    className={classNames(selected === item.name ? 'text-gray-400 rotate-90' : 'text-gray-300', "text-gray-300 mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150")}
                                    viewBox="0 0 20 20" aria-hidden="true">
                                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor"/>
                                </svg>
                                {item.name}
                            </button>
                            : null}

                        {selected == item.name ?
                            item.subItems.map(subItem => {
                                if (context.hasPermission(subItem.permission))
                                    return <div key={subItem.id} className="p-1 pl-8 text-xs"><Link
                                        to={subItem.path}>{props.render(subItem, false)}</Link></div>
                                return null;
                            })
                            : null}
                    </Fragment>
                })}
            </nav>
        </div>
    )
}


const Home: React.FC = () => {

    const context = useContext(AppContext);
    const [showMenuMobile, setShowMenuMobile] = useState(false);
    const [showMenuNormal, setShowMenuNormal] = useState(true);
    const [showUpdate, setShowUpdate] = useState<boolean>(false);
    const app = useContext(AppContext)

    useEffect(() => {
        signal
            .start()
            .then(() => {
                signal.on('Version', (version: string) => {
                    if (version !== context.initial.version) setShowUpdate(true)
                })
                signal.send('ClientVersion', {version: context.initial.version})
            })
    }, [])

    function logout() {
        wrapLoader(context, AuthController.logout(), () => {
            window.location.href = '/'
        })
    }
    
    function hideMenu() {
        setShowMenuNormal(false)
    }

    return (
        <div>
            {showUpdate ? <div className='sticky top-0 z-50 w-full h-[5rem] border-8 bg-primary-600 px-10'>
                <div className='flex w-full h-[4rem] items-center justify-between'>
                    <div className=' text-xl text-white'>
                        New version is available. Please save your work and update.
                    </div>
                    <div className='btn bg-primary' onClick={() => {
                        setShowUpdate(false)
                        window.location.reload()
                    }}>
                        Update
                    </div>
                </div>
            </div> : null}
            {
                app.initial.isDev 
                    ? <div className='sticky top-0 z-50 w-full h-[4rem] border-4 bg-red-500 px-10 py-3'>
                            <div className='text-2xl text-white'>
                                This is a testing environment.
                            </div>
                        </div> 
                    : null
            }
            <div className="h-screen flex overflow-hidden bg-gray-100">
                {!showMenuMobile ? null : <div v-show="showMenuMobile" className="md:hidden">
                    <div className="fixed inset-0 flex z-40">
                        <div className="fixed inset-0">
                            <div className="absolute inset-0 bg-gray-600 opacity-75"/>
                        </div>
                        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                            {/* Mobile menu */}
                            <div className="flex-1 h-0 pb-4 overflow-y-auto">
                                <div className='flex justify-between items-center'>
                                    {/*Heroicon arrow left*/}
                                    <div className='pt-3 pr-3 ml-auto' onClick={() => setShowMenuMobile(false)} >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2" stroke="white" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
                                        </svg>
                                    </div>
                                </div>
                                <Menu className="mt-5 flex-1 px-2 bg-gray-800 space-y-1" render={(item, active) => {
                                    return <span
                                        onClick={() => showUpdate ? window.location.href = item.path : null}
                                        className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 text-base font-medium rounded-md', active ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white')}>
                                        {item.display}    
                                    </span>
                                }}/>

                            </div>
                            <div className="flex-shrink-0 flex bg-gray-700 p-4">
                                <a href="#" className="flex-shrink-0 block w-[100%]">
                                    <div className="flex items-center justify-between">
                                        <p className="text-xs text-white p-1">v{context.initial.version}</p>
                                        <p onClick={logout}
                                           className="text-xs font-medium text-red-400 hover:text-red-200">
                                            Logout
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="flex-shrink-0 w-14">
                            {/* Force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </div>
                }

                {/* Static sidebar for desktop */}
                {
                    showMenuNormal ? <div className="hidden md:flex md:flex-shrink-0">
                        <div className="flex flex-col w-48">
                            {/* Sidebar component, swap this element with another sidebar if you like */}
                            <div className="flex flex-col h-0 flex-1 bg-gray-800">
                                <div className='flex justify-between items-center'>
                                    {/*Heroicon arrow left*/}
                                    <div className='pt-3 pr-3 ml-auto' onClick={hideMenu}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2" stroke="white" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">

                                    <Menu className="flex-1 px-2 bg-gray-800 space-y-1" render={(item, active) => {
                                        return <span
                                            onClick={() => showUpdate ? window.location.href = item.path : null}
                                            className={classNames('pl-6 group flex text-left items-center py-2 text-sm font-medium rounded-md', active ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white')}>
                                        {item.display}
                                    </span>
                                    }}/>

                                </div>
                                <div className="flex-shrink-0 flex bg-gray-700 pl-2 pr-3 py-5">
                                    <a href="#" className="flex-shrink-0 w-full block">
                                        <div className="flex items-center">
                                            <div className="ml-3 group">
                                                <p className="text-sm font-medium text-white">
                                                    {context.initial.name}
                                                </p>
                                            </div>
                                            <p className="text-xs text-white font-medium">v{context.initial.version}</p>

                                            <p onClick={logout}
                                               className="text-xs font-medium text-red-400 hover:text-red-200 ml-auto">
                                                Logout
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }

                <div className="flex flex-col w-0 flex-1 overflow-hidden">
                    <div className={classNames("pl-1 pt-1 sm:pl-3 sm:pt-3", showMenuNormal ? 'md:hidden' : '')}>
                        <button
                            onClick={() => {
                                setShowMenuMobile(true)
                                setShowMenuNormal(true)
                            }} className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center
            rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset
            focus:ring-primary-500">
                            <span className="sr-only">Open sidebar</span>
                            {/* Heroicon name: menu */}
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M4 6h16M4 12h16M4 18h16"/>
                            </svg>
                        </button>
                    </div>
                    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex={0}>
                        <div className="p-2">
                            <Outlet/>
                        </div>
                    </main>
                </div>
            </div>

            {/*            <teleport to="#portal">*/}
            {/*        <transition name="fade">*/}
            {/*            <div v-if="showUpgrade" className="fixed right-0 bottom-0 bg-red-400 p-2 text-white m-2">Brace yourself for*/}
            {/*                the next upgrade.*/}
            {/*            </div>*/}
            {/*        </transition>*/}
            {/*    </teleport>*/}
            {/*    <loader-comp v-model:show="loader"/>*/}
            {/*    <transition name="slidedown">*/}
            {/*        <div className="snackbar shadow-lg" v-if="snack.key"*/}
            {/*        :key="snack.key" :class="snack.type">*/}
            {/*        <div className="snackbar-content">*/}
            {/*            <div className="cell left">{'{'}{'{'} snack.text {'}'}{'}'}</div>*/}
            {/*            <div className="cell right action font-bold"*/}
            {/*            @click="dismiss">Dismiss</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*</transition>*/}
        </div>
    );
}

export default Home;