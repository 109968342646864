import React, {useRef} from "react";
import FarmersSampleController from "../../controllers/FarmersSampleController";
import {dateFormat} from "../../date";
import PagedSearchTable, {PagedTableFunctions} from "../../components/PagedSearchTable";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import TestFarmerSampleResponse from "../../controllers/TestFarmerSampleResponse";

const FarmersSamplesTab: React.FC<{
    archived: boolean,
    results: boolean,
    notCompleted: boolean
}> = (props) => {
    const pagedTableRef = useRef<PagedTableFunctions<TestFarmerSampleResponse>>()
    const confirmationWarningState = useWarningState<number>(0)

    function archiveWarning(id: number, archive: boolean) {
        confirmationWarningState.show('Are you sure you want to archive this row?', id)
    }
    
    function callerComp() {
        if (props.notCompleted) {
            return FarmersSampleController.notCompleted
        }
        if (props.archived) {
            return FarmersSampleController.archived
        }
        return FarmersSampleController.completed
    }

    function getJson(id: number) {

    }

    function archive() {

    }
    
    return <>
        <PagedSearchTable
            componentRef={pagedTableRef}
            call={callerComp()}
            columns={[
                {
                    header: "lab",
                    key: "lab",
                    row: (item) => item.lab
                },
                {
                    header: "sample",
                    key: "sample",
                    row: (item) => item.sampleNumber
                },
                {
                    header: <>
                        Client (Farm)
                        <div className="text-xs whitespace-nowrap text-gray-500">
                            Block
                        </div>
                    </>,
                    key: "clientAndFarm",
                    row: (item, index) =>
                        <>
                            {item.clientName} ({item.farmName})
                            <div className="text-xs whitespace-nowrap text-gray-500">
                                {item.blockNumber}
                            </div>
                        </>
                },
                {
                    header: "crop",
                    key: "crop",
                    row: (item) => item.crop
                },
                {
                    header: <>
                        Agent
                        <div className="text-xs whitespace-nowrap text-gray-500">
                            Agronomist
                        </div>
                    </>,
                    row: (item, index) => <>
                        {item.agent}
                        <div className="text-xs whitespace-nowrap text-gray-500">
                            {item.agronomist}
                        </div>
                    </>
                },
                {
                    header: <>
                        Date Synced
                        <div className="text-xs whitespace-nowrap text-gray-500">
                            Date Captured
                        </div>
                    </>,
                    row: (item, index) => <>
                        {dateFormat(item.inserted)}
                        <div className="text-xs text-gray-500">
                            {dateFormat(item.appAdded)}
                        </div>
                    </>
                },
                {
                    header: <div className='text-right'>Actions</div>,
                    row: (item) => <div className='text-right'>
                        <button className="mr-1 btn-primary btn-sm" onClick={_ => getJson(item.id)}>json</button>
                        <button className="btn-error btn-sm" onClick={_ => archiveWarning(item.id, true)}>x</button>
                    </div>
                }
            ]}/>
        <WarningPopup state={confirmationWarningState} onYes={() => archive()}/>

    </>
} 

export default FarmersSamplesTab;