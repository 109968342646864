import React, {useContext, useMemo, useRef, useState} from "react";
import PaginationRequestSearch from "../../controllers/PaginationRequestSearch";
import {AxiosResponse} from "axios";
import PaginationResponse from "../../controllers/PaginationResponse";
import SubmissionDisplay from "../../controllers/SubmissionDisplay";
import AppContext from "../../appContext";
import PreReportData from "../../controllers/PreReportData";
import Data from "../../controllers/Data";
import PagedSearchTable, {PagedTableFunctions, TableColumn} from "../../components/PagedSearchTable";
import {arrayPush, arrayRemoveItem, useStateSetterArray} from "../../immutableState";
import File from "../../controllers/File";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import {classNames, wrapLoader} from "../../wrapper";
import Lab from "../../controllers/Lab";
import CheckBox from "../../components/CheckBox";
import {dateFormat} from "../../date";
import Dialog from "../../components/Dialog";
import {DownloadSvg} from "../Submissions";
import FarmersSampleController from "../../controllers/FarmersSampleController";
import SubmissionReportDataFarmResponseApi from "../../controllers/SubmissionReportDataFarmResponseApi";
import FarmersReportPopup from "./FarmersReportPopup";
import Success from "../../components/Success";
import Failed from "../../components/Failed";


const CompletedResultsFarmer: React.FC<{
    canPost: boolean,
    call: (request: PaginationRequestSearch) => Promise<AxiosResponse<PaginationResponse<SubmissionDisplay>, any>>
}> = (props) => {
    const context = useContext(AppContext);

    const [printData, setPrintData] = useState<number | null>(null);
    const [reportData, setReportData] = useState<PreReportData<SubmissionReportDataFarmResponseApi> | null>(null);
    const [resultData, setResultData] = useState<Data[] | null>(null);
    const pagedTableRef = useRef<PagedTableFunctions<SubmissionDisplay>>()
    const [selectedReports, setSelectedReports, updateSelectedReports] = useStateSetterArray<number>([])
    const [id, setId] = useState(0);
    const [files, setFiles] = useState<File[]>([])

    const postMfwState = useWarningState<number>(0)

    function report(row: SubmissionDisplay) {
        setId(row.id);
        // window.location.href = FarmersSampleController.urls.questPdf(row.id);
        
        wrapLoader(context, FarmersSampleController.preReport({ ids: [row.id] }), data => {
            setReportData(data);
        })
    }

    function checkPostToMfw(row: SubmissionDisplay) {
        if (row.postedToMfw) {
            postMfwState.show("Are you sure you want to post to MFW again?", row.id)
        } else {
            postMfwState.show("Are you sure you want to post to MFW?", row.id)
        }
    }

    function postToMfw(id: number) {
        wrapLoader(context, FarmersSampleController.postToMfw({ id }), (data) => {
            if (data.status) {
                pagedTableRef.current?.refresh()
                context.showSnack(<Success title={"Success"} />)
            } else {
                context.showSnack(<Failed title={data.message ?? "Something went wrong"} />, 10000)
            }
        })
    }

    function results(row: SubmissionDisplay) {
        setId(row.id);
        // wrapLoader(context, SubmissionController.getResults({ id: row.id }), data => {
        //     setResultData(data);
        // })
    }

    function print(row: SubmissionDisplay) {
        setId(row.id);
        setPrintData(row.id);
    }

    function setSelectedList(val: number) {
        if (selectedReports.includes(val)) {
            setSelectedReports(arrayRemoveItem(selectedReports, val))
        } else {
            setSelectedReports(arrayPush(selectedReports, val))
        }
    }

    function combineReports() {
        wrapLoader(context, FarmersSampleController.preReport({ ids: selectedReports }), data => {
            setReportData(data);
        })
    }

    function getDocks(row: SubmissionDisplay) {
        // const hideLoader = context.showLoader();
        // SubmissionController.getDocs({id: row.id})
        //     .then(resp => {
        //         if (resp.data.length == 0)
        //             context.showSnack(<CustomSnack svg={<FileSvg/>} title={"No files found."} />)
        //         setFiles(resp.data)
        //     })
        //     .finally(hideLoader)
    }

    const options = (isManual: boolean) => {
        return isManual
            ? Object.entries({
                results: "results",
                docs: "docs"
            })
            : Object.entries({
                results: "results",
                print: "print",
                docs: "docs"
            })
    }

    function selectOption(event: React.ChangeEvent<HTMLSelectElement>, item: SubmissionDisplay) {
        const value = event.target.value;
        if (value == "results") {
            results(item)
        } else if (value == "print") {
            print(item)
        } else if (value == "docs") {
            getDocks(item)
        }
    }

    const getActions = (item: SubmissionDisplay) => <div className="flex justify-end">
        {
            props.canPost && item.hasLocation
                ? <div className={classNames("btn-sm", item.postedToMfw ? "bg-gray-400" : "btn-primary")}
                       onClick={() => checkPostToMfw(item)}>
                    post to mfw
                </div>
                : null
        }

        <div className="btn-primary btn-sm " onClick={() => report(item)}>
            report
        </div>
        <select value={'empty'}
                className="bg-center bg-no-repeat outline-none btn-primary btn-sm p-2 w-9 "
                style={{
                    appearance: "none",
                    backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' fill=\'none\' viewBox=\'0 0 24 24\' stroke-width=\'2\' stroke=\'white\' class=\'w-6 h-6\'><path stroke-linecap=\'round\' stroke-linejoin=\'round\' d=\'M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5\'/></svg>")'
                }}
                onChange={e => selectOption(e, item)}>
            <option value="empty" className="hidden" disabled={true}></option>
            {options(item.lab == Lab.Manual).map(([text, value]) => <option key={value} value={value}>{text}</option>)}
        </select>
    </div>

    const tableData = useMemo(() => {
        let tableData: TableColumn<SubmissionDisplay>[] = [{
            header: '',
            row: item => <CheckBox onChange={_ => setSelectedList(item.id)}
                                   checked={selectedReports.includes(item.id)}/>
        },
            {
                header: 'Id',
                row: item => item.id
            },
            {
                header: 'Client (Farm)',
                row: item => <span>{item.clientName} ({item.farmName})</span>
            },
            {
                header: 'Samples',
                row: item => <span>{item.items} ({item.crop.join(', ')})</span>
            },
            {
                header: 'Submitted On / Completed On',
                row: item => <span>{dateFormat(item.submitted) || "_"} / {dateFormat(item.completed)}</span>
            }]

        if (props.canPost) {
            tableData.push(
                {
                    header: 'Date Posted To mfw',
                    row: item => <span>{dateFormat(item.postedToMfwDate, '%d %M %Y %H:%i') || "_"}</span>
                })
        }

        tableData.push({
            header: <div className='text-right'>Actions</div>,
            row: item => getActions(item)
        })
        return tableData;
    }, [props.canPost, selectedReports]);


    return <>
        <WarningPopup state={postMfwState} onYes={postToMfw}/>
        {/*{printData != null ?*/}
        {/*    <Dialog title="Lab Results"*/}
        {/*            body={<Print id={printData} />} show={true}*/}
        {/*            setShow={() => setPrintData(null)} />*/}
        {/*    : null}*/}

        {reportData != null ?
            <Dialog title="Report Settings"
                    body={<FarmersReportPopup data={reportData} />} show={true}
                    setShow={() => setReportData(null)} />
            : null}

        {/*{resultData != null ?*/}
        {/*    <Dialog title="Lab Result"*/}
        {/*            body={<LabResult id={id} data={resultData} />} show={true}*/}
        {/*            setShow={() => setResultData(null)} />*/}
        {/*    : null}*/}

        {selectedReports.length > 1
            ? <div className="btn-primary btn-sm m-2" onClick={() => combineReports()}>Combine Reports</div>
            : null}

        <PagedSearchTable componentRef={pagedTableRef} keyExtractor={r => r.id}
                          call={props.call} columns={tableData}/>

        <Dialog show={files.length > 0} setShow={() => setFiles([])} title="Files">
            {
                files.map(file => <div key={file.id} className="flex justify-between items-center m-2">
                    <div>{file.name}</div>
                    <a
                        href={"/upload/" + file.path}
                        download
                        className="flex items-center justify-between bg-primary text-white p-2 rounded-lg ml-3 hover:opacity-90"
                    >
                        <div className="mr-2">
                            Download
                        </div>
                        <DownloadSvg/>
                    </a>
                </div>)
            }
        </Dialog>
    </>
}

export default CompletedResultsFarmer;