import React, {useContext} from "react";
import {PrepareSubmissionParams, useSubmissionPrepare} from "./useSubmissionPrepare";
import {Fields} from "../../components/Fields";
import Input from "../../components/Input";
import {SelectString} from "../../components/SelectString";
import Dialog from "../../components/Dialog";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import FileUploader from "../../components/FileUploader";
import SubmissionDataResponse from "../../controllers/SubmissionDataResponse";
import SelectNumber from "../../components/SelectNumber";
import {DatePicker, DatePickerType} from "../../components/DatePicker";
import AppContext from "../../appContext";
import Lab from "../../controllers/Lab";
import type {AxiosResponse} from "axios";
import type ReportDataRequest from "../../controllers/ReportDataRequest";

export interface SubmissionPrepareBaseProps<TSample> {
    testDataIds: number[];
    close: (saved: boolean) => void;

    prepare: (params: PrepareSubmissionParams) => Promise<AxiosResponse<SubmissionDataResponse<TSample>>>;
    submit: (request: ReportDataRequest<TSample>) => Promise<AxiosResponse<ReportDataRequest<TSample>>>;
    
    // Available lab
    labs: Lab[];

    // Additional differences can be provided here:
    csvPopulateMap: Record<string, (value: string, sample: TSample) => void>;
    renderTable: ( // function that renders the table(s)
        report: SubmissionDataResponse<TSample>,
        data: TSample[][],
        onDataChange: (newData: TSample[][]) => void
    ) => React.ReactNode;
}

export function SubmissionPrepareBase<TSample>(props: SubmissionPrepareBaseProps<TSample>) {
    const context = useContext(AppContext);

    const {
        isLoading,
        submissionData,
        updateHeader,
        splitData,
        setSplitData,
        handleSplit,
        handleCsvUpload,
        handleSubmit,
        validation,
    } = useSubmissionPrepare<TSample>(
        {
            prepareFn: props.prepare,
            submitFn: props.submit,
            csvPopulateMap: props.csvPopulateMap,
        },
        props.testDataIds
    );

    const warningState = useWarningState(null);
    const [showUpload, setShowUpload] = React.useState<{ show: boolean; index: number }>({
        show: false,
        index: -1,
    });

    if (isLoading) {
        return (
            <div className="absolute inset-0 bg-overlay-200 flex justify-center items-center">
                <img src="/images/loader.gif" alt=""/>
            </div>
        );
    }

    if (!submissionData) {
        return null;
    }

    // parse out from submissionData
    const dataHeader = submissionData.data;

    function onCsvFileSelected(file: File) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target?.result as string;
            // parseCSVFile<TSample>(...) – however you do it; 
            // then call handleCsvUpload(showUpload.index, parsedRows);
        };
        reader.readAsText(file);
    }

    function showWarningIfValidationPassed() {
        // check your custom validations 
        if (!validation.validate()) {
            return;
        }
        warningState.show("Are you sure you want to submit this submission?", null);
    }

    return (
        <div className="p-4 text-left">
            <Fields
                columns={2}
                fields={[
                    {
                        label: "Entity Name",
                        value: (
                            <Input
                                value={dataHeader.clientName}
                                change={(v) => updateHeader({clientName: v})}
                                className={!validation.rules.entity ? "border border-red-500" : ""}
                            />
                        ),
                    },
                    {
                        label: "Agent",
                        value: (
                            <Input
                                value={dataHeader.agent}
                                change={(v) => updateHeader({agent: v})}
                            />
                        ),
                    },
                    {
                        label: "Farm Name",
                        value: (
                            <Input
                                value={dataHeader.farmName}
                                change={(v) => updateHeader({farmName: v})}
                                className={!validation.rules.farm ? "border border-red-500" : ""}
                            />
                        ),
                    },
                    {
                        label: 'Language',
                        value: <SelectNumber options={context.initial.languages}
                                             textFunc={r => r.name ?? ''} valueFunc={r => r.id}
                                             value={dataHeader.languageId}
                                             onChange={v => updateHeader({languageId: v})}/>
                    },
                    {
                        label: 'Agronomist',
                        value: <SelectNumber options={submissionData.agronomists} textFunc={r => r.name ?? ''}
                                             valueFunc={r => r.id} value={dataHeader.agronomistId}
                                             onChange={v => updateHeader({agronomistId: v})}/>
                    },
                    {
                        label: 'Sent on',
                        value: <DatePicker value={dataHeader.dateSent} setValue={d => updateHeader({dateSent: d})}
                                           type={DatePickerType.Date}/>
                    },
                    {
                        label: 'Depot',
                        value: <Input value={dataHeader.depot} change={v => updateHeader({depot: v})}
                                      className={!validation.rules.depot ? "border border-red-500" : ""}/>
                    },
                    {
                        label: 'Date Samples',
                        value: <DatePicker value={dataHeader.dateSamples}
                                           setValue={d => updateHeader({dateSamples: d})}
                                           type={DatePickerType.Date}/>
                    }
                    ,
                    {
                        label: 'Submission Lab',
                        value: <SelectString value={dataHeader.lab} onChange={v => updateHeader({lab: v as Lab})}
                                             options={props.labs.reduce((acc, lab) => {
                                                 acc[lab] = lab;
                                                 return acc;
                                             }, {} as Record<string, string>)}/>
                    }
                ]}
            />

            {/* Now render the dynamic table(s). */}
            {props.renderTable(submissionData, splitData, setSplitData)}

            <div className="flex justify-end items-center p-2 border-t sticky bottom-0 bg-white">
                {/*{!validation.rules ? (*/}
                {/*    <div className="text-xs text-red-500 px-2">*/}
                {/*        (each submission must include a minimum of one active row)*/}
                {/*    </div>*/}
                {/*) : null}*/}

                <div className="btn bg-red-500" onClick={() => props.close(false)}>
                    Cancel
                </div>
                <div className="btn bg-primary-500" onClick={showWarningIfValidationPassed}>
                    Submit
                </div>
            </div>

            <Dialog
                title="Upload CSV"
                body={
                    <div className="p-4">
                        <div>Download CSV Sample, add data and upload again.</div>
                        <a className="underline" href="/SubmissionSample.csv" download="SubmissionSample.csv">
                            SubmissionSample.csv
                        </a>
                        <div className="my-2">
                            <FileUploader onChange={onCsvFileSelected} fileTypes=".csv"/>
                        </div>
                    </div>
                }
                show={showUpload.show}
                setShow={() => setShowUpload({show: false, index: -1})}
            />

            <WarningPopup state={warningState} onYes={() => {
                handleSubmit()
                    .then(_ => {
                        props.close(false)
                    })
            }}/>
        </div>
    );
}