import React, {useContext, useRef, useState} from "react";
import AppContext from "../../appContext";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import PagedSearchTable, {PagedTableFunctions} from "../../components/PagedSearchTable";
import SubmissionDisplay from "../../controllers/SubmissionDisplay";
import SubmissionController from "../../controllers/SubmissionController";
import {showSuccessOrFailed} from "../../Snacks";
import Dialog from "../../components/Dialog";
import ManualLabResult from "../ManualLabResult";
import {dateFormat} from "../../date";
import Lab from "../../controllers/Lab";
import PermissionEnum from "../../controllers/PermissionEnum";
import FarmersSampleController from "../../controllers/FarmersSampleController";
import FarmerManualLabResult from "./FarmerManualLabResult";

const WaitingFarmersSampleSubmissions: React.FC = () => {

    const context = useContext(AppContext)
    const [printData, setPrintData] = useState<{ show: boolean, id: number }>({id: 0, show: false});
    const [addLabId, setAddLabId] = useState<{ show: boolean, submissionId: number }>({submissionId: 0, show: false});
    const [manualData, setManualData] = useState<{ show: boolean, submissionId: number }>({
        submissionId: 0,
        show: false
    });
    const confirmation = useWarningState<number>(0);
    const [jobIdCheck, setJobIdCheck] = useState(false);
    const pagedTableRef = useRef<PagedTableFunctions<SubmissionDisplay>>();

    function print(item: SubmissionDisplay) {
        setPrintData({id: item.id, show: true})
    }

    function manualSubmit() {
        setManualData({submissionId: 0, show: false})
        pagedTableRef.current?.refresh();
    }

    function manual(item: SubmissionDisplay) {
        setManualData({submissionId: item.id, show: true})
    }

    function clickLabId(item: SubmissionDisplay) {
        setAddLabId({submissionId: item.id, show: true})
    }

    function warning(item: SubmissionDisplay) {
        confirmation.show(`Are you sure you want to delete row ${item.clientName}?`, item.id);
    }

    function deleteRow(id: number) {
        // FarmersSampleController.delete({id}).then(resp => {
        //     if (!resp.data) {
        //         // cannot delete row with Lab/ job ids
        //         setJobIdCheck(true);
        //         return;
        //
        //     }
        //     showSuccessOrFailed(context, SubmissionController.delete({id})).then(resp => {
        //         confirmation.hide()
        //         pagedTableRef.current?.refresh();
        //     });
        // });

    }

    function closeLabId() {
        setAddLabId({submissionId: 0, show: false})
        pagedTableRef.current?.refresh();
    }

    function getSubmissionData(item: SubmissionDisplay) {
        return `${item.lab}${item.lab != Lab.ManualFarmer ? " - " + item.submissionWorkOrder: ""}`;
    }

    return (<>

            <Dialog title="May not delete row"
                    body={<div className="p=5 m-3">May not delete row with Lab Job number</div>}
                    show={jobIdCheck}
                    setShow={() => setJobIdCheck(false)}/>

            {/*<Dialog title="Lab Results"*/}
            {/*        body={<Print id={printData.id}/>} show={printData.show}*/}
            {/*        setShow={() => setPrintData({id: 0, show: false})}/>*/}
            
            {/*<Dialog title="Job Id"*/}
            {/*        body={<LinkJobId submissionId={addLabId.submissionId} close={closeLabId}/>} show={addLabId.show}*/}
            {/*        setShow={closeLabId}/>*/}

            <Dialog title="Manual Submission"
                    show={manualData.show}
                    setShow={manualSubmit}>
                <FarmerManualLabResult onClose={manualSubmit} submissionId={manualData.submissionId}/>
            </Dialog>

            <WarningPopup state={confirmation} onYes={deleteRow}/>

            <PagedSearchTable componentRef={pagedTableRef} call={FarmersSampleController.pagedWaiting}
                              keyExtractor={s => s.id} columns={[
                {
                    header: 'Id',
                    row: item => item.id,
                    classNames: item => item.error.length > 0 ? 'bg-red-200' : ''
                },
                {
                    header: 'Prepared',
                    row: item => dateFormat(item.prepared),
                    classNames: item => item.error.length > 0 ? 'bg-red-200' : ''
                },
                {
                    header: 'Submitted',
                    row: item => dateFormat(item.submitted),
                    classNames: item => item.error.length > 0 ? 'bg-red-200' : ''
                },
                {
                    header: 'Submission',
                    row: item => item.error.length > 0 ? item.error : getSubmissionData(item),
                    colspan: item => item.error.length > 0 ? 5 : 1,
                },
                {
                    header: 'Client (farm)',
                    row: item => <span className="table-data">{item.clientName} ({item.farmName})</span>,
                    colspan: item => item.error.length > 0 ? 0 : 1
                },
                {
                    header: 'Samples',
                    row: item => <span> {item.items} ({item.crop.join(', ')})</span>,
                    colspan: item => item.error.length > 0 ? 0 : 1
                },
                {
                    header: 'Lab Job / Work Numbers',
                    row: item => item.labJobId || item.labWorkOrder ?
                        <span> {item.labJobId} ({item.labWorkOrder})</span> : null,
                    colspan: item => item.error.length > 0 ? 0 : 1
                },
                {
                    header: <div className='text-right'>Actions</div>,
                    row: item => <div className='text-right'>
                        {item.lab == Lab.Manual || item.lab == Lab.ManualFarmer
                            ?
                            <div className="btn-primary btn-sm" onClick={() => manual(item)}>
                                Manual
                            </div>


                            : item.labJobId || item.labWorkOrder
                                ? null
                                : <div className="btn-primary btn-sm" onClick={() => clickLabId(item)}>
                                    Manual Link
                                </div>
                        }
                        <div className="btn-primary btn-sm" onClick={() => print(item)}>
                            print
                        </div>
                        {!item.labJobId && context.hasPermission(PermissionEnum.SubmissionsDelete)
                            ? <div className="btn-error btn-sm" onClick={() => warning(item)}>
                                x
                            </div>
                            : null
                        }
                    </div>
                },
            ]}/>
        </>
    );
}

export default WaitingFarmersSampleSubmissions;