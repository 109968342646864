import React, {useContext, useEffect, useState} from "react";
import {EditRow, EditTable} from "../components/Fields";
import Input from "../components/Input";
import Dialog from "../components/Dialog";
import IdName from "../controllers/IdName";
import CarboResultsDisplayed from "../controllers/CarboResultsDisplayed";
import {buildSetter} from "../immutableState";
import AppContext from "../appContext";
import LabController from "../controllers/LabController";
import IdNameDisplay from "../controllers/IdNameDisplay";
import CarbohydrateTissueWithOrgans from "../controllers/CarbohydrateTissueWithOrgans";
import SelectNumber from "../components/SelectNumber";
import SelectNumberNullable from "../components/SelectNumberNullable";
import {filterGrowths, getCultivarsForCropId, getRegionsForCropId} from "./lab/createjob";
import {useValidation} from "../validation";
import {classNames} from "../wrapper";
import {inputStyling} from "../styles";


function emptyCarboResultsDisplayed(): CarboResultsDisplayed {
    return {
        id: 0,
        completedOn: null,
        date: null,
        dated: null,
        createdOn: new Date(),
        sampleDate: new Date(),
        entity: null,
        farmName: null,
        client: null,
        agriculturist: null,
        agent: null,
        sampleNumber: "",
        crop: "",
        cropId: 0,
        tissueId: 0,
        cropGrowthId: 0,
        linkedTissueName: "",
        cultivar: null,
        cultivarId: null,
        regionName: null,
        regionId: null,
        fieldBlockNumber: null,
        cropGrowth: "",
        tissue: "",
        sugar: null,
        starch: null,
        sugarExcel: "",
        sugarStarch: "",
        carbs: null,
        carboHydrateId: null,
        group: 0
    };
}


const EditCarboPopup: React.FC<{
    setShow: (show: boolean) => void
    data: CarboResultsDisplayed
    refresh: (() => void) | undefined
}> = (props) => {
    const context = useContext(AppContext)
    const [crops, setCrops] = useState<IdNameDisplay[]>([])
    const [cultivars, setCultivars] = useState<IdName[]>([])
    const [regions, setRegions] = useState<IdName[]>([])
    const [organOptions, setOrganOptions] = useState<CarbohydrateTissueWithOrgans[]>([])
    
    const [carboData, _setCarboData] = useState<CarboResultsDisplayed>(props.data ?? emptyCarboResultsDisplayed())
    const [setCarboData] = buildSetter(carboData, _setCarboData)

    useEffect(() => {
        const hideLoader = context.showLoader()
        Promise.all([LabController.cropsActive(), LabController.tissueTypesActive()])
            .then(([crops, activeTissues]) => {
                setCrops(crops.data)
                setOrganOptions(activeTissues.data)
                // have to use crops.data as crops has not been set to crops.data at this moment 
                setCultivarsAndRegionsForCropId(carboData.cropId, crops.data, false)
            })
            .finally(() => {
                hideLoader()
            })
    }, [])
    
    const validation = useValidation({
        entity: () => carboData.entity != null && carboData.entity.trim().length > 0,
        agent: () => carboData.agent != null && carboData.agent.trim().length > 0,
        agriculturist: () => carboData.agriculturist != null && carboData.agriculturist.trim().length > 0,
        cropId: () => carboData.cropId > 0,
        cultivar: () => carboData.cultivarId != null && carboData.cultivarId > 0,
        tissue: () => carboData.tissueId > 0,
        cropGrowth: () => carboData.cropGrowthId > 0,
        farmName: () => carboData.farmName != null && carboData.farmName.trim().length > 0,
        fieldBlockNumber: () => carboData.fieldBlockNumber != null && carboData.fieldBlockNumber.trim().length > 0,
    })
    
    function closeEdit() {
        props.setShow(false)
    }

    function setCultivarsAndRegionsForCropId(cropId: number, cropList: IdNameDisplay[], cropChanged: boolean) {
        setRegions(getRegionsForCropId(cropId, cropList))
        setCultivars(getCultivarsForCropId(cropId, cropList))
        
        if (!cropChanged) return;
        // only set the crop id and everything to 0/null if the crop has changed
        setCarboData({
            cropId: cropId,
            cultivarId: null,
            regionId: null,
            tissueId: 0,
            cropGrowthId: 0
        })
    }
    
    function saveCarbo() {
        if (!validation.validate()) return;
        const hideLoader = context.showLoader()
        LabController.upsertCarbohydrateResult(carboData)
            .then(() => {
                closeEdit()
                props.refresh?.()
            })
            .finally(() => {
                hideLoader()
            })
    }
    
    return <Dialog title="Edit carbohydrate" show={true} body={<>
        <EditTable discard={closeEdit}
                   save={() => saveCarbo()}
                   saveWord={'update'}>
            {
                EditRow('Sample Number', 
                    <div className={classNames("input bg-gray-100")}>{carboData.sampleNumber}</div>)
            }
            {
                EditRow('Entity', 
                    <Input className={inputStyling(!validation.rules.entity)} value={carboData?.entity ?? ""}
                                         change={v => setCarboData({entity: v})}/>
                )
            }
            {
                EditRow('Agent', 
                    <Input className={inputStyling(!validation.rules.agent)}
                           value={carboData?.agent ?? ""}
                           change={v => setCarboData({agent: v})}/>
                )
            }
            {
                EditRow('Agronomist', 
                    <Input className={inputStyling(!validation.rules.agriculturist)}
                        value={carboData?.agriculturist ?? ""}
                        change={v => setCarboData({agriculturist: v})}/>
                )
            }
            {
                EditRow('Farm Name', 
                    <Input className={inputStyling(!validation.rules.farmName)}
                        value={carboData?.farmName ?? ""}
                        change={v => setCarboData({farmName: v})}/>
                )
            }
            {
                EditRow('Field Block Number', 
                    <Input className={inputStyling(!validation.rules.fieldBlockNumber)}
                        value={carboData?.fieldBlockNumber ?? ""}
                        change={v => setCarboData({fieldBlockNumber: v})}/>
                )
            }
            {
                EditRow('Crop', 
                    <SelectNumber
                        className={inputStyling(!validation.rules.cropId)}
                        options={crops}
                        textFunc={c => c.name}
                        valueFunc={c => c.id}
                        value={carboData.cropId}
                        onChange={v => {
                            setCultivarsAndRegionsForCropId(v, crops, true)
                        }}
                    />,
                )
            }
            {
                EditRow('Cultivar', 
                    <SelectNumberNullable
                        className={inputStyling(!validation.rules.cultivar)}
                        options={cultivars}
                        textFunc={c => c.name}
                        valueFunc={c => c.id}
                        value={carboData.cultivarId}
                        onChange={v => {
                            setCarboData({cultivarId: v})
                        }}
                    />
                )
            }
            {
                // no validation required for region
                EditRow('Region', 
                    <SelectNumberNullable
                        options={regions}
                        textFunc={c => c.name}
                        valueFunc={c => c.id}
                        value={carboData.regionId}
                        onChange={v => {
                            setCarboData({regionId: v})
                        }}
                    />
                )
            }
            {
                EditRow('Organ Type',
                    <SelectNumber
                        className={inputStyling(!validation.rules.tissue)}
                        options={organOptions}
                        textFunc={c => c.name ?? ''}
                        valueFunc={c => c.id}
                        value={carboData.tissueId}
                        onChange={v => {
                            setCarboData({tissueId: v})
                        }}
                    />
                )
            }
            {
                EditRow('Growth', 
                    <SelectNumber
                        className={inputStyling(!validation.rules.cropGrowth)}
                        options={filterGrowths(carboData.cropId, carboData.tissueId, organOptions)}
                        textFunc={c => c.name}
                        valueFunc={c => c.id}
                        value={carboData.cropGrowthId}
                        onChange={v => {
                            setCarboData({cropGrowthId: v})
                        }}
                    />
                )
            }
        </EditTable>
    </>
    } setShow={closeEdit}/>
}

export default EditCarboPopup;