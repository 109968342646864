import React, {Fragment, useContext, useRef} from "react";
import {Tab} from "@headlessui/react";
import PermissionEnum from "../../controllers/PermissionEnum";
import {classes} from "../Leafs";
import AppContext from "../../appContext";
import PagedSearchTable, {PagedTableFunctions} from "../../components/PagedSearchTable";
import FarmersSampleController from "../../controllers/FarmersSampleController";
import TestFarmerSampleResponse from "../../controllers/TestFarmerSampleResponse";
import {dateFormat} from "../../date";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import FarmersSamplesTab from "../farmersamples/FarmersSamplesTab";

const FarmersSampleEntries: React.FC = () => {
    const context = useContext(AppContext);

    return <>
        <div className="bg-white p-2 shadow">
            <Tab.Group defaultIndex={-1}>
                <Tab.List className="flex space-x-1 rounded-xl bg-primary-500 p-1">
                    <Tab as={Fragment}>
                        {({selected}) => (
                            <button className={classes(selected)}>
                                Not Completed
                            </button>
                        )}
                    </Tab>
                    <Tab as={Fragment}>
                        {({selected}) => (
                            <button className={classes(selected)}>
                                Completed
                            </button>
                        )}
                    </Tab>
                    {context.hasPermission(PermissionEnum.FarmersSampleEntries) ?
                        <Tab as={Fragment}>
                            {({selected}) => (
                                <button className={classes(selected)}>
                                    Results
                                </button>
                            )}
                        </Tab>
                        : null}
                    <Tab as={Fragment}>
                        {({selected}) => (
                            <button className={classes(selected)}>
                                Archived
                            </button>
                        )}
                    </Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <FarmersSamplesTab archived={false} notCompleted={true} results={false}/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <FarmersSamplesTab archived={false} notCompleted={false} results={false}/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <FarmersSamplesTab archived={false} notCompleted={false} results={true}/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <FarmersSamplesTab archived={true} notCompleted={false} results={false}/>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
            
        </div>
    </>;
}

export default FarmersSampleEntries;