import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import PagedSearchTable, {PagedCall, PagedTableFunctions} from "../../components/PagedSearchTable";
import PendingSubmissionQuery from "../../controllers/PendingSubmissionQuery";
import {dateFormat} from "../../date";
import PaginationRequestSearch from "../../controllers/PaginationRequestSearch";
import SubmissionPrepare from "./SubmissionPrepare";
import Dialog from "../../components/Dialog";

export interface PendingSubmissionsRef {
    refresh: () => void;
}

export const PendingSubmissions = forwardRef<PendingSubmissionsRef, {
    call: PagedCall<PaginationRequestSearch, PendingSubmissionQuery>;
    prepare: (item: PendingSubmissionQuery) => void;
    archive: (dataIds: number[]) => void;
    newSubmission: () => void;
}>((props, ref) => {
    const warningState = useWarningState<number[]>([]);
    const pagedTableRef = useRef<PagedTableFunctions<PendingSubmissionQuery>>();

    // Expose the `refresh` method using useImperativeHandle
    useImperativeHandle(ref, () => ({
        refresh: () => {
            pagedTableRef.current?.refresh();
        },
    }));

    function warning(row: PendingSubmissionQuery) {
        warningState.show(`Are you sure you want to delete ${row.clientName}?`, row.dataIds);
    }

    return (
        <>
            <WarningPopup state={warningState} onYes={(data) => props.archive(data)}/>
            <button className="btn-sm btn-primary m-2" onClick={props.newSubmission}>
                New
            </button>
            <PagedSearchTable
                componentRef={pagedTableRef}
                call={props.call}
                columns={[
                    {
                        header: "Day",
                        row: (item) => dateFormat(item.date),
                    },
                    {
                        header: "Client",
                        row: (item) => item.clientName,
                    },
                    {
                        header: "Farm",
                        row: (item) => item.farmName,
                    },
                    {
                        header: "Samples",
                        row: (item) => item.samples,
                    },
                    {
                        header: "Crops",
                        row: (item) => item.crops.join(", "),
                    },
                    {
                        header: "Latest entry",
                        row: (item) => dateFormat(item.latest),
                    },
                    {
                        header: <div className="text-right">Actions</div>,
                        row: (item) => (
                            <div className="text-right">
                                <div className="btn-primary btn-sm" onClick={() => props.prepare(item)}>
                                    prepare
                                </div>
                                <div className="btn-error btn-sm" onClick={() => warning(item)}>
                                    x
                                </div>
                            </div>
                        ),
                    },
                ]}
            />
        </>
    );
});