import {
    arrayUpdatePartial,
    ArrayUpdateType,
    useStateSetter,
    useStateSetterArray
} from "../../immutableState";
import AppContext from "../../appContext";
import React, {useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {DatePicker, DatePickerType} from "../../components/DatePicker";
import {Fields} from "../../components/Fields";
import SelectNumber from "../../components/SelectNumber";
import CheckBox from "../../components/CheckBox";
import Dialog from "../../components/Dialog";
import {classNames, wrapLoader} from "../../wrapper";
import Input from "../../components/Input";
import ReportDataResult from "../../controllers/ReportDataResult";
import Success from "../../components/Success";
import Failed from "../../components/Failed";
import {XCircleIcon} from "@heroicons/react/24/outline";
import PreReportData from "../../controllers/PreReportData";
import SubmissionController from "../../controllers/SubmissionController";
import {useValidation} from "../../validation";
import {checkIfValidForWindowsZip} from "../submission/SubmissionPrepare";
import SubmissionReportFarmerDataResponse from "../../controllers/SubmissionReportFarmerDataResponse";
import TestFarmerSampleReportDataResponse from "../../controllers/TestFarmerSampleReportDataResponse";
import SubmissionReportDataFarmResponseApi from "../../controllers/SubmissionReportDataFarmResponseApi";
import UpdateReportDataRequestFarm from "../../controllers/UpdateReportDataRequestFarm";
import UpdateReportDataFarmersSamples from "../../controllers/UpdateReportDataFarmersSamples";
import FarmersSampleController from "../../controllers/FarmersSampleController";
import ReportDataResultFarm from "../../controllers/ReportDataResultFarm";
import UpdateReportDataLeafs from "../../controllers/UpdateReportDataLeafs";

interface ReportHeader {
    submissionId: number;
    languageId: number;
    clientName: string;
    farmName: string;
    agronomistId: number;
    agent: string;
    distributorId: number;
    addressId: number;
}

interface ReportFarm extends UpdateReportDataFarmersSamples {
    selected: boolean;
}

interface ReportRef {
    requestUpdate: () => UpdateReportDataRequestFarm;
}

const ReportForward: React.ForwardRefRenderFunction<ReportRef, {
    samples: ReportFarm[];
    data: PreReportData<SubmissionReportDataFarmResponseApi>;
    header: ReportHeader;
    id: number;
    extraLabSampleNumbers: string[];
}> = (props, ref) => {
    const [header, setHeader, updateHeader] = useStateSetter(props.header);
    const [samples, setSamples, updateSamples] = useStateSetterArray(props.samples);

    const [headerCrop, setHeaderCrop] = useState<{ cropId: number}>({cropId: 0})

    function setSelectedSamples(data: Partial<ReportFarm>) {
        setSamples(samples.map(sample => sample.selected ? {...sample, ...data} : sample));
    }


    function changeHeaderCrop(cropId: number) {
        setHeaderCrop({cropId})
        setSelectedSamples({
            cropId,
        })
    }
    

    function selectedLeafs(): ReportFarm[] {
        // only download selected samples if some are selected
        return samples.filter(l => l.selected)
    }

    const validation = useValidation({
        clientName: () => checkIfValidForWindowsZip(header.clientName),
        farmName: () => checkIfValidForWindowsZip(header.farmName),
    })

    useImperativeHandle(ref, () => ({
        requestUpdate() {
            // run the validation to show if the input is not valid but don't do anything here if it's not valid
            validation.validate()
            return {
                addressId: header.addressId,
                agent: header.agent,
                agronomistId: header.agronomistId,
                clientName: header.clientName,
                distributorId: header.distributorId,
                farmName: header.farmName,
                languageId: header.languageId,
                samples: selectedLeafs().map<UpdateReportDataFarmersSamples>(leaf => ({
                    cropId: leaf.cropId,
                    dateCaptured: leaf.dateCaptured,
                    id: leaf.id,
                    notes: leaf.notes,
                    sample: leaf.sample,
                    dataId: leaf.dataId,
                    blockNumber: leaf.blockNumber
                })),
                dataId: props.id,
                submissionId: props.header.submissionId
            }
        }
    }));

    return <div>
        <Fields columns={1} fields={[
            {
                label: 'Language',
                value: <SelectNumber options={props.data.languages}
                                     textFunc={r => r.name ?? ''} valueFunc={r => r.id}
                                     value={header.languageId}
                                     onChange={v => updateHeader({languageId: v})}/>
            },
            {
                label: 'Client Name',
                value: <Input className={validation.rules.clientName ? "" : "border border-red-500"} value={header.clientName} change={v => updateHeader({clientName: v})}/>
            },
            {
                label: 'Farm Name',
                value: <Input className={validation.rules.farmName ? "" : "border border-red-500"} value={header.farmName} change={v => updateHeader({farmName: v})}/>
            },
            {
                label: 'Agronomist',
                value: <SelectNumber options={props.data.agronomists} textFunc={r => r.name ?? ''}
                                     valueFunc={r => r.id} value={header.agronomistId}
                                     onChange={v => updateHeader({agronomistId: v})}/>
            },
            {
                label: 'Agent',
                value: <Input value={header.agent} change={v => updateHeader({agent: v})}/>
            },
            {
                label: 'Distributor',
                value: <SelectNumber options={props.data.distributors} textFunc={r => r.name ?? ''}
                                     valueFunc={r => r.id} value={header.distributorId}
                                     onChange={v => updateHeader({distributorId: v})}/>
            },
            {
                label: 'Address',
                value: <SelectNumber options={props.data.addresses} textFunc={r => r.name ?? ''}
                                     valueFunc={r => r.id} value={header.addressId}
                                     onChange={v => updateHeader({addressId: v})}/>
            },
        ]}/>

        <table className="w-full">
            <thead>
            <tr className='bg-gray-100'>
                <th className="p-2 text-xs">
                    <div>Select</div>
                    <div className="text-center">
                        <CheckBox checked={samples.every(l => l.selected)}
                                  onChange={v => setSamples(samples.map(l => ({...l, selected: v})))}/>
                    </div>
                </th>
                <th className="pl-4 text-xs">Sample</th>
                <th className="pl-4 text-xs">Date</th>
                <th className="pl-4 text-xs">Block</th>
                <th className="pl-4 text-xs">
                    <div>Crop</div>
                    <SelectNumber options={props.data.crops} textFunc={c => c.name ?? ''} valueFunc={c => c.id}
                                  value={headerCrop.cropId} onChange={v => changeHeaderCrop(v)}/>
                </th>
                <th></th>
            </tr>

            </thead>
            <tbody>
            {samples.map((leaf, index) =>
                <tr className={classNames(index % 2 === 0 ? '' : 'bg-gray-100', 'group hover:bg-gray-200 cursor-pointer')}
                    key={leaf.id}>
                    <td>
                        <CheckBox checked={leaf.selected} onChange={v => updateSamples(index, {selected: v})}/>
                    </td>
                    <td>
                        {leaf.sample}
                        <div className="text-xs text-gray-500">{leaf.notes}</div>
                    </td>
                    <td>
                        <DatePicker value={leaf.dateCaptured} setValue={d => updateSamples(index, {dateCaptured: d})}
                                    type={DatePickerType.Date}/>
                    </td>
                    <td>
                        <Input value={leaf.blockNumber} change={v => updateSamples(index, {blockNumber: v})}/>
                    </td>
                    <td>
                        <SelectNumber options={props.data.crops} textFunc={c => c.name ?? ''} valueFunc={c => c.id}
                                      value={leaf.cropId}
                                      onChange={v => updateSamples(index, {cropId: v})}/>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
        <div>
            {props.extraLabSampleNumbers.length > 0 ?
                <>
                    <p>Samples received from the lab that's not in the report:</p>
                    {props.extraLabSampleNumbers.map((e, i) => <p key={i}>{e}</p>)}
                </>
                : <></>
            }
        </div>

    </div>
}


function convertHeader(data: SubmissionReportFarmerDataResponse): ReportHeader {
    return {
        submissionId: data.submissionId,
        addressId: data.address.id,
        agent: data.agent,
        agronomistId: data.agronomistId,
        clientName: data.clientName,
        distributorId: data.distributor.id,
        farmName: data.farmName,
        languageId: data.languageId
    };
}

function convertLeafs(samples: TestFarmerSampleReportDataResponse[]): ReportFarm[] {
    return samples.map<ReportFarm>(sample => ({
        id: sample.id,
        cropId: sample.crop.id,
        dateCaptured: new Date(sample.dateCaptured),
        matchedResult: sample.sampleMatched,
        notes: sample.notes,
        sample: sample.sample,
        selected: true,
        excludeImage: false,
        dataId: sample.dataId,
        blockNumber: sample.blockNumber
    })) 
}

// if there is no matched data.
const reportError = <Failed text="Could not generate a report, because there is no matched report data."
                            title="Could not generate report"/>;

// if there is no leafs selected 
const samplesError = <Failed text="Could not generate a report, because no samples have been selected."
                          title="No leafs selected"/>;

const Report = React.forwardRef(ReportForward);

const FarmersReportPopup: React.FC<{ data: PreReportData<SubmissionReportDataFarmResponseApi> }> = (props) => {
    const context = useContext(AppContext);
    const reportRef = useRef<(ReportRef | null)[]>([])
    const [loadingIndex, setLoadingIndex] = useState<{ loading: boolean, failed: boolean, selectedIndex: number }>({loading: false, failed: false, selectedIndex: 0})
    // useRef to access from outside 
    const reportDataLength = useRef(0);

    const validation = useValidation({
        validClientAndFarmNames: () => {
            return reportRef.current
                .filter(r => r != null)
                .map(r => r!.requestUpdate())
                .every(s => checkIfValidForWindowsZip(s.clientName) && checkIfValidForWindowsZip(s.farmName))
        }
    })

    function updateReport() {
        if (!validation.validate()) return;

        setLoadingIndex({loading: true, failed: false, selectedIndex: 0})
        const update = reportRef.current
            .filter(r => r != null)
            .map(r => r!.requestUpdate());
        

        wrapLoader(context, 
            FarmersSampleController.updateReportData(update), 
            data => {
            if (data.length == 0) {
                context.showSnack(reportError);
                return;
            }
            
            window.location.href = FarmersSampleController.urls.questPdf(data[0]!.submissionId)
            context.showSnack(<Success title={"Starting download"}/>)
        });
    }

    return <div className="p-4">
        {props.data.reports.map((report, index) =>
            <div key={report.farmerData.id} className="border m-2 p-2 border-primary shadow-md">
                <Report
                    ref={r => reportRef.current[index] = r}
                    id={report.farmerData.id}
                    data={props.data}
                    extraLabSampleNumbers={report.extraSampleNumbers}
                    header={convertHeader(report.farmerData)}
                    samples={convertLeafs(report.farmerData.samples)}
                />
            </div>)}

        <div className="flex items-center justify-end bg-white  p-2 border-t sticky bottom-0 ">
            <div className="btn bg-primary-500"
                 onClick={() => {
                     updateReport()
                 }}>
                Submit
            </div>
        </div>
    </div>
}


export default FarmersReportPopup;