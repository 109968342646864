import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Fields} from "../../components/Fields";
import Input from "../../components/Input";
import SubmissionController from "../../controllers/SubmissionController";
import {useStateAjax} from "../../wrapper";
import SubmissionDataResponse from "../../controllers/SubmissionDataResponse";
import {
    arrayUpdate,
    arrayUpdatePartial,
    useStateSetter,
} from "../../immutableState";
import AppContext from "../../appContext";
import SelectNumber from "../../components/SelectNumber";
import AgronomistController from "../../controllers/AgronomistController";
import {DatePicker, DatePickerType} from "../../components/DatePicker";
import {groupBySingle, insertAt, split} from "../../array";
import TestType from "../../controllers/TestType";
import PrepareSubmissionLeaf from "../../controllers/PrepareSubmissionLeaf";
import SubmissionTable, {SubmissionPrepareFunctions} from "./SubmissionTable";
import ReportDataResponse from "../../controllers/ReportDataResponse";
import {showSuccessOrFailed} from "../../Snacks";
import Failed from "../../components/Failed";
import {CSVPopulate, parseCSVFile} from "../CSVParse";
import Dialog from "../../components/Dialog";
import FileUploader from "../../components/FileUploader";
import CropCultivar from "../../controllers/CropCultivar";
import Lab from "../../controllers/Lab";
import {SelectString} from "../../components/SelectString";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import {useValidation} from "../../validation";

export function checkIfValidForWindowsZip(s: string) {
    return true
    return !/[^a-z0-9_.@ ()-]/i.test(s)
}

const SubmissionPrepare: React.FC<{
    dataIds: number[];
    close: (saved: boolean) => void
}> = (props) => {
    const context = useContext(AppContext);
    const [data, setData] = useState<SubmissionDataResponse<PrepareSubmissionLeaf> | null>(null);
    useEffect(() => {
        SubmissionController.prepareSubmission({testDataIds: props.dataIds}).then(resp => {
            setData(resp.data)
        }).catch(() => {
            props.close(true)
            context.showSnack(<Failed title={'Failed to prepare submission.'}/>)
        })
    }, [])

    if (data == null)
        return <div className="absolute inset-0 bg-overlay-200 flex justify-center items-center">
            <img src='/images/loader.gif' alt=''/>
        </div>

    return <SubmissionPrepareData close={props.close} data={data}/>
}

// extraction for each leaf row
interface LeafRow extends PrepareSubmissionLeaf {
    selected: boolean;
}

function emptyLeafRow(): LeafRow {
    return {
        testId: 0,
        testDataId: null,
        submissionId: null,
        blockNumber: '',
        growthStage: '',
        sampleNumber: '',
        notes: '',
        cropId: 0,
        cultivars: [],
        delete: false,
        test: TestType.StandardItestLeaf,
        optionalTests: [],
        agronomist: '',
        farmName: '',
        depot: '',
        clientName: '',
        agent: '',
        appAdded: new Date(),
        selected: false,
        rawLab: ''
    }
}


const SubmissionPrepareData: React.FC<{
    data: SubmissionDataResponse<PrepareSubmissionLeaf>
    close: (saved: boolean) => void
}> = (props) => {
    const submitRef = useRef<SubmissionPrepareFunctions>()
    const context = useContext(AppContext);
    const warningState = useWarningState(null);

    const [leafHeader, setLeafHeader, updateLeafHeader] = useStateSetter<ReportDataResponse<PrepareSubmissionLeaf>>(props.data.data);

    const [showUpload, setShowUpload] = useState<{show: boolean, index: number}>({show: false, index: -1});
    const cultivars = useMemo(() => groupBySingle(props.data.crops, c => c.id, c => c.cultivars), [props.data.crops]);

    const [agronomist,] = useStateAjax(AgronomistController.index, []);

    const [splitData, setSplitData] = useState<LeafRow[][]>([props.data.data.samples.map(leaf => ({...leaf, selected: false}))])
    
    function submit(samples: LeafRow[][]) {
        
        const prom = SubmissionController.submit({
            agent: leafHeader.agent,
            agronomistId: leafHeader.agronomistId,
            area: '',
            clientName: leafHeader.clientName,
            dateSamples: leafHeader.dateSamples,
            dateSent: leafHeader.dateSent,
            depot: leafHeader.depot,
            farmName: leafHeader.farmName,
            languageId: leafHeader.languageId,
            samples: samples,
            sentBy: '',
            waybill: '',
            lab: leafHeader.lab
        });

        showSuccessOrFailed(context, prom).then(() => {
            props.close(true);
        });
    }
    
    function showWarningIfValidationPassed() {
        if (!submitRef.current?.validate() || !validation.validate()) return

        warningState.show('Are you sure you want to submit this submission?', null)
    }
    
    function checkSubmissions(): boolean {
        return splitData.every(leafs => leafs.length > 0 && leafs.some(leaf => !leaf.delete))
    }
    
    function checkTestTypes(): boolean {
        return splitData.every(leafs => leafs.every(leaf => leaf.test !== TestType.Unknown))
    }
    
    const validation = useValidation({
        entity: () => leafHeader.clientName.trim().length > 0 && checkIfValidForWindowsZip(leafHeader.clientName.trim()),
        farm: () => leafHeader.farmName.trim().length > 0 && checkIfValidForWindowsZip(leafHeader.farmName.trim()),
        depot: () => leafHeader.depot.trim().length > 0,
        dataValidation: () => submitRef.current?.validate() ?? false,
        validateActive: () => checkSubmissions(),
        validateTestTypes: () => checkTestTypes()
    })
    
    function addRow(index: number) {
        const newArray = splitData.map((c, i) => index !== i ? c : c.concat(emptyLeafRow()))
        setSplitData(newArray)
    }
    
    function openCsvButton(index: number) {
        setShowUpload({show: true, index})
    }
    
    function findCropFromCSV(cropName: string) {
        return props.data.crops.find(c => c.name.toLowerCase().trim() === cropName.toLowerCase().trim())?.id;
    }
    function findCultivarFromCSV(cultivarName: string, crop: CropCultivar) {
        return crop.cultivars.find(c => c.name.toLowerCase().trim() === cultivarName.toLowerCase().trim())?.id;
    }
    
    const CSVLeafData: Record<string, CSVPopulate<LeafRow>> = {
        'Sample Number': (value, entry) => {
            entry.sampleNumber = value;
        },
        'Block': (value, entry) => {
            entry.blockNumber = value;
        },
        'Crop': (value, entry) => {
            entry.cropId = findCropFromCSV(value) ?? -1;
        },
        'Cultivars': (value, entry) => {
            const crop = props.data.crops.find(c => c.id == entry.cropId)
            if (!crop) return;
            entry.cultivars = value
                .split(',')
                .map(cultivar => {
                    if (cultivar == '') return -1;
                    const c = findCultivarFromCSV(cultivar, crop);
                    return c ?? -1;
                })
                .filter(s => s !== -1);
        },
        'Growth phase': (value, entry) => {
            entry.growthStage = value;
        },
        'Test': (value, entry) => {
            const testsGiven = value.toLowerCase().trim();
            const tests = props.data.tests.find(t => testsGiven == t.value.toLowerCase().trim())?.key;
            if (!tests) return;
            entry.test = tests;
        },
        'Optional tests': (value, entry) => {
            const optionalTestsGiven = value.split(',').map(s => s.toLowerCase().trim());
            entry.optionalTests =  props.data.optionalTests
                .filter(t => optionalTestsGiven.includes(t.value.toLowerCase().trim()))
                .map(t => t.key);
        },
    }

    
    function closeUpload() {
        setShowUpload({
            show: false,
            index: -1
        })
    }
    
    function csvUpload (file: File, index: number) {
        const reader = new FileReader()
        reader.onload = e => {
            const text: string = e.target?.result as string
            const entries = parseCSVFile<LeafRow>(text, index => emptyLeafRow(), CSVLeafData)
            setSplitData(arrayUpdatePartial(splitData, index, s => s.concat(entries)))
            closeUpload()
        }
        reader.readAsText(file)
    }
    
    function allSelectedOrUnselected(data: LeafRow[]){
        return data.filter(s => s.selected).length > 0 && data.length !== data.filter(s => s.selected).length
    }

    return <div className="p-4  text-left">
        <Fields columns={2} fields={[
            {
                label: 'Entity Name',
                value: <Input value={leafHeader.clientName} change={v => updateLeafHeader({clientName: v})} className={!validation.rules.entity ? "border border-red-500" : ""}/>
            },
            {
                label: 'Agent',
                value: <Input value={leafHeader.agent} change={v => updateLeafHeader({agent: v})}/>
            },
            {
                label: 'Farm Name',
                value: <Input value={leafHeader.farmName} change={v => updateLeafHeader({farmName: v})} className={!validation.rules.farm ? "border border-red-500" : ""}/>
            },
            {
                label: 'Language',
                value: <SelectNumber options={context.initial.languages}
                                     textFunc={r => r.name ?? ''} valueFunc={r => r.id}
                                     value={leafHeader.languageId}
                                     onChange={v => updateLeafHeader({languageId: v})}/>
            },
            {
                label: 'Agronomist',
                value: <SelectNumber options={agronomist} textFunc={r => r.name ?? ''}
                                     valueFunc={r => r.id} value={leafHeader.agronomistId}
                                     onChange={v => updateLeafHeader({agronomistId: v})}/>
            },
            {
                label: 'Sent on',
                value: <DatePicker value={leafHeader.dateSent} setValue={d => updateLeafHeader({dateSent: d})}
                                   type={DatePickerType.Date}/>
            },
            {
                label: 'Depot',
                value: <Input value={leafHeader.depot} change={v => updateLeafHeader({depot: v})} className={!validation.rules.depot ? "border border-red-500" : ""}/>
            },
            {
                label: 'Date Samples',
                value: <DatePicker value={leafHeader.dateSamples} setValue={d => updateLeafHeader({dateSamples: d})}
                                   type={DatePickerType.Date}/>
            }
            ,
            {
                label: 'Submission Lab',
                value: <SelectString value={leafHeader.lab} onChange={v => updateLeafHeader({lab: v as Lab})}
                                     options={[Lab.Manual, Lab.Nvirotek].reduce((acc, lab) => {
                                         acc[lab] = lab;
                                         return acc;
                                     }, {} as Record<string, string>)}/>
            }
        ]}/>
        
        {
            splitData.map((data, index) => 
                <div key={index}>
                    <SubmissionTable index={index} data={props.data} leafs={data} submitRef={submitRef} setData={d => {
                        setSplitData(arrayUpdate(splitData, index, d))
                    }} cultivars={cultivars}/>
                    
                    <div className='flex justify-between p-1 mb-1 pb-4 border-b border-gray-400'>
                        <div className='flex items-center'>
                            <div className='btn bg-primary-500' onClick={() => addRow(index)}>+</div>
                            <div className='btn bg-primary-500' onClick={() => openCsvButton(index)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"/>
                                </svg>
                            </div>
                        </div>
                        
                        { allSelectedOrUnselected(data) 
                            ? <div className='btn bg-primary' onClick={() => {
                                    const part = split(data, d => d.selected);
                                    const mutate = splitData.map(d => d);
                                    mutate.splice(index, 1);
                                    insertAt(mutate, index, part.one.map(d => ({...d, selected: false})), part.two);
                                    setSplitData(mutate);
                                }}>Split</div> 
                            : null
                        }
                    </div>
                </div>
            )
        }
        <div className="flex justify-end items-center p-2 border-t sticky bottom-0 bg-white">
            {
                !validation.rules.validateActive 
                    ? <div className="text-xs text-red-500 px-2">(each submission must include a minimum of one active row)</div>
                    : null
            }
            {
                !validation.rules.validateTestTypes 
                    ? <div className="text-xs text-red-500 px-2">(test type can't be unknown)</div>
                    : null
            }
            <div className="btn bg-red-500" onClick={() => props.close(false)}>Cancel</div>
            <div className="btn bg-primary-500"
                 onClick={() => showWarningIfValidationPassed()}>Submit
            </div>
        </div>

        <Dialog title='Upload CSV' body={
            <div className="p-4">
                <div>Download CSV Sample, add data and upload again.</div>
                <a className="underline" href="/SubmissionSample.csv"
                   download="SubmissionSample.csv">SubmissionSample.csv</a>
                <div className="my-2">
                    <FileUploader onChange={file => csvUpload(file, showUpload.index)} fileTypes=".csv"/>
                </div>
            </div>} show={showUpload.show} setShow={() => closeUpload()}
        />

        <WarningPopup state={warningState} onYes={() => submit(splitData)}/>
    </div>
}

export default SubmissionPrepare;