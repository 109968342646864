import React, {Fragment, useContext, useRef, useState} from "react";
import {Tab} from "@headlessui/react";
import PermissionEnum from "../../controllers/PermissionEnum";
import AppContext from "../../appContext";
import {classes} from "../Leafs";
import FarmersSampleController from "../../controllers/FarmersSampleController";
import {PendingSubmissions, PendingSubmissionsRef} from "../submission/PendingSubmissions";
import PendingSubmissionQuery from "../../controllers/PendingSubmissionQuery";
import Dialog from "../../components/Dialog";
import SubmissionPrepareFarmer from "./SubmissionPrepareFarmer";
import WaitingFarmersSampleSubmissions from "./WaitingFarmersSampleSubmissions";
import CompletedResultsFarmer from "./CompletedResultsFarmer";

const FarmersSampleSubmissions: React.FC = () => {
    const context = useContext(AppContext)
    const pendingSubmissionsRef = useRef<PendingSubmissionsRef>(null);

    const [showPrepare, setShowPrepare] = useState(false);
    const [prepareData, setPrepareData] = useState<PendingSubmissionQuery>();

    function newShow() {
        setPrepareData(undefined);
        setShowPrepare(true);
    }
    function show(row: PendingSubmissionQuery) {
        setPrepareData(row);
        setShowPrepare(true);
    }

    return <div className="bg-white p-2 shadow">

        <Tab.Group defaultIndex={-1}>
            <Tab.List className="flex space-x-1 rounded-xl bg-primary-500 p-1">
                {
                    context.hasPermission(PermissionEnum.SubmissionsPrepare) ?
                        <Tab as={Fragment}>
                            {({selected}) => (
                                <button className={classes(selected)}>
                                    Prepare
                                </button>
                            )}
                        </Tab>
                        : null
                }
                {
                    context.hasPermission(PermissionEnum.SubmissionsWaitingForLab) ?
                        <Tab as={Fragment}>
                            {({selected}) => (
                                <button className={classes(selected)}>
                                    Waiting for Lab
                                </button>
                            )}
                        </Tab>
                        : null
                }
                {
                    context.hasPermission(PermissionEnum.SubmissionsResults) ?
                        <Tab as={Fragment}>
                            {({selected}) => (
                                <button className={classes(selected)}>
                                    Results
                                </button>
                            )}
                        </Tab>
                        : null
                }
                {
                    context.hasPermission(PermissionEnum.SubmissionsCompleted) ?
                        <Tab as={Fragment}>
                            {({selected}) => (
                                <button className={classes(selected)}>
                                    Completed
                                </button>
                            )}
                        </Tab>
                        : null
                }
            </Tab.List>

            <Tab.Panels>
                {
                    context.hasPermission(PermissionEnum.SubmissionsPrepare) ?
                        <Tab.Panel>
                            <PendingSubmissions
                                ref={pendingSubmissionsRef}
                                newSubmission={() => {
                                    newShow()
                                }}
                                call={FarmersSampleController.pendingSubmissions}
                                archive={() => {
                                }}
                                prepare={(item) => show(item)}/>
                        </Tab.Panel>
                        : null
                }
                {
                    context.hasPermission(PermissionEnum.SubmissionsWaitingForLab) ?
                        <Tab.Panel>
                            <WaitingFarmersSampleSubmissions/>
                        </Tab.Panel>
                        : null
                }
                {
                    context.hasPermission(PermissionEnum.SubmissionsResults) ?
                        <Tab.Panel>
                            <CompletedResultsFarmer canPost={false} call={FarmersSampleController.pagedResults}/>
                        </Tab.Panel>
                        : null
                }
                {
                    context.hasPermission(PermissionEnum.SubmissionsCompleted) ?
                        <Tab.Panel>
                            <CompletedResultsFarmer canPost={true} call={FarmersSampleController.pagedResults}/>
                        </Tab.Panel>
                        : null
                }
            </Tab.Panels>
        </Tab.Group>

        <Dialog
            title="Prepare Submission"
            body={
                <SubmissionPrepareFarmer
                    close={() => {
                        setShowPrepare(false)
                        pendingSubmissionsRef.current?.refresh();
                    }}
                    dataIds={prepareData?.dataIds ?? []}
                />
            }
            show={showPrepare}
            setShow={(state) => {
                setShowPrepare(state)
                pendingSubmissionsRef.current?.refresh();
            }}
        />
    </div>;
}

export default FarmersSampleSubmissions;