/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */

enum PermissionEnum {
    Users = "Users",
    Roles = "Roles",
    Leafs = "Leafs",
    Submissions = "Submissions",
    Labmin = "Labmin",
    Crop = "Crop",
    Language = "Language",
    Address = "Address",
    Distributors = "Distributors",
    Admin = "Admin",
    CarbohydrateEntries = "CarbohydrateEntries",
    CarbohydrateTissues = "CarbohydrateTissues",
    Settings = "Settings",
    Agronomists = "Agronomists",
    FarmersSampleSubmissions = "FarmersSampleSubmissions",
    FarmersSampleEntries = "FarmersSampleEntries",
    LeafResults = "LeafResults",
    SubmissionsPrepare = "SubmissionsPrepare",
    SubmissionsWaitingForLab = "SubmissionsWaitingForLab",
    SubmissionsResults = "SubmissionsResults",
    SubmissionsCompleted = "SubmissionsCompleted",
    SubmissionsDelete = "SubmissionsDelete",
    LabResults = "LabResults",
    LabJobs = "LabJobs",
    DrisGraphs = "DrisGraphs"
}

export default PermissionEnum;
