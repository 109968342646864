import {useContext, useEffect, useState} from "react";
import SubmissionDataResponse from "../../controllers/SubmissionDataResponse";
import {arrayUpdatePartial} from "../../immutableState";
import AppContext from "../../appContext";
import {insertAt, split} from "../../array";
import ReportDataResponse from "../../controllers/ReportDataResponse";
import {showSuccessOrFailed} from "../../Snacks";
import {useValidation} from "../../validation";
import ReportDataRequest from "../../controllers/ReportDataRequest";
import {checkIfValidForWindowsZip} from "./SubmissionPrepare";
import type {AxiosResponse} from "axios";

/**
 * Type definitions
 */
export type PrepareSubmissionParams = {
    testDataIds: number[];
};


type UseSubmissionPrepareArgs<TSample> = {
    // Called to fetch the prepare 
    prepareFn: (params: PrepareSubmissionParams) => Promise<AxiosResponse<SubmissionDataResponse<TSample>>>;
    // Submit function
    submitFn: (request: ReportDataRequest<TSample>) => Promise<AxiosResponse<ReportDataRequest<TSample>>>;

    /** Additional CSV column definitions if they differ between your two components. */
    csvPopulateMap?: Record<string, (value: string, sample: TSample) => void>;
};


export function useSubmissionPrepare<TSample>(
    args: UseSubmissionPrepareArgs<TSample>,
    testDataIds: number[]
) {
    const {prepareFn, submitFn, csvPopulateMap} = args;
    const context = useContext(AppContext);

    // local states
    const [isLoading, setIsLoading] = useState(true);
    const [submissionData, setSubmissionData] = useState<SubmissionDataResponse<TSample> | null>(null);
    const [splitData, setSplitData] = useState<TSample[][]>([]);

    // load the data on mount
    useEffect(() => {
        setIsLoading(true);
        prepareFn({testDataIds})
            .then((resp) => {
                setSubmissionData(resp.data);
                // we'll store splitData as just one chunk initially
                setSplitData([resp.data.data.samples]);
            })
            .catch((error) => {
                context.showSnack("Failed to prepare submission.");
            })
            .finally(() => setIsLoading(false));
    }, [testDataIds]);

    // For the input fields in the header
    function updateHeader(partial: Partial<ReportDataResponse<TSample>>) {
        if (!submissionData) return;
        setSubmissionData({
            ...submissionData,
            data: {
                ...submissionData.data,
                ...partial,
            },
        });
    }

    // An example CSV parser
    function handleCsvUpload(index: number, newRows: TSample[]) {
        setSplitData(arrayUpdatePartial(splitData, index, (old) => old.concat(newRows)));
    }

    // For splitting rows, if each group has a "selected" property
    function handleSplit(index: number, isSelected: (sample: TSample) => boolean) {
        const chunk = splitData[index]!;
        const parted = split(chunk, isSelected);

        const newAll = splitData.slice();
        // remove the chunk we split
        newAll.splice(index, 1);

        // insert parted arrays at same index
        insertAt(newAll, index, parted.one, parted.two);
        setSplitData(newAll);
    }

    // We can define a function that, upon pressing "submit," does final validation
    // and calls the actual `submitFn`.
    async function handleSubmit() {
        if (!submissionData) return;

        // For demonstration, a minimal check:
        if (!validate()) {
            return;
        }

        // Build a final object that your real submitFn expects
        const data = submissionData.data;
        const finalObj: ReportDataRequest<TSample> = {
            area: "",
            sentBy: "",
            waybill: "",
            agent: data.agent,
            agronomistId: data.agronomistId,
            clientName: data.clientName,
            dateSamples: data.dateSamples,
            dateSent: data.dateSent,
            depot: data.depot,
            farmName: data.farmName,
            languageId: data.languageId,
            lab: data.lab,
            samples: splitData
        };

        try {
            const prom = submitFn(finalObj);
            await showSuccessOrFailed(context, prom);
            // etc. Then maybe return "success" or something that the component can use
        } catch (err) {
            console.error("Submit error", err);
        }
    }

    // We can incorporate the same `useValidation` used by your UI
    const validation = useValidation({
        entity: () =>
            submissionData!.data.clientName.trim().length > 0 &&
            checkIfValidForWindowsZip(submissionData?.data.clientName.trim() ?? ""),
        farm: () =>
            submissionData!.data.farmName.trim().length > 0 &&
            checkIfValidForWindowsZip(submissionData?.data.farmName.trim() ?? ""),
        depot: () => (submissionData?.data.depot.trim().length ?? 0) > 0,
        // ...
    });

    function validate() {
        return validation.validate();
    }

    return {
        isLoading,
        submissionData,
        updateHeader,
        splitData,
        setSplitData,
        handleSplit,
        handleCsvUpload,
        handleSubmit,
        validation,
    };
}