import React, {MutableRefObject, useImperativeHandle, useState} from "react";
import {arrayPush, arrayRemoveIndex, arrayUpdate, arrayUpdatePartial, ArrayUpdateType} from "../../immutableState";
import CheckBox from "../../components/CheckBox";
import SelectNumber from "../../components/SelectNumber";
import {classNames} from "../../wrapper";
import Input from "../../components/Input";
import TestType from "../../controllers/TestType";
import PrepareSubmissionFarmer from "../../controllers/PrepareSubmissionFarmer";
import SubmissionDataResponse from "../../controllers/SubmissionDataResponse";
import Cultivar from "../../controllers/Cultivar";
import {useValidationArray} from "../validationArray";
import {FarmerRow} from "./SubmissionPrepareFarmer";

export interface SubmissionPrepareFunctions {
    validate: () => boolean;
}


const SubmissionTable: React.FC<{
    cultivars: Record<number, Cultivar[]>
    leafs: (FarmerRow)[]
    data: SubmissionDataResponse<FarmerRow>
    setData: (data: (FarmerRow)[]) => void
    index: number
    submitRef?: MutableRefObject<SubmissionPrepareFunctions | undefined>;
}> = (props) => {
    
    const [headerCrop, setHeaderCrop] = useState(0);
    const [headerCultivar, setHeaderCultivar] = useState<number[]>([]);
    const [split , setSplit] = useState<boolean>(false)
    
    useImperativeHandle(props.submitRef, () => ({
        validate: () => validation.validate()
    }));
    
    function lengthCheck(length: number, deleted: boolean) {
        // if it's deleted, it's fine and the validation should pass
        return length > 0 || deleted;
    }

    function duplicatesampleNumberCheck(sampleNumber: string) {
        return props.leafs.filter(l => l.sampleNumber === sampleNumber).length === 1;
    }

    const validation = useValidationArray(props.leafs)({
        sampleNumber: leaf => lengthCheck(leaf.sampleNumber.length, leaf.delete) && duplicatesampleNumberCheck(leaf.sampleNumber),
        block: leaf => lengthCheck(leaf.blockNumber.length, leaf.delete),
        crop: leaf => lengthCheck(leaf.cropId, leaf.delete),
    })
    
    function setLeafDataIndex(index: number, update: ArrayUpdateType<FarmerRow>) {
        props.setData(arrayUpdatePartial( props.leafs, index, update))
    }
    
    function setLeafData(update: (leaf: FarmerRow) => FarmerRow) {
        const leafs = props.leafs;
        props.setData(leafs.map((l, i) => {
            return update(l)
        }))
    }


    function updateHeaderCrop(cropId: number) {
        setHeaderCrop(cropId);
        setHeaderCultivar([])

        setLeafData(l => {
            if (!l.selected)
                return l;
            return {...l, cropId: cropId, cultivars: []};
        })
    }

    function addHeaderCultivar() {
        // select the first one from the list
        const list = props.cultivars[headerCrop] ?? [];
        if (list.length > 0) {
            updateCultivarRows(arrayPush(headerCultivar, list[0]!.id))
        }
    }

    function changeHeaderCultivar(cultivarIndex: number, cultivarId: number) {
        updateCultivarRows(arrayUpdate(headerCultivar, cultivarIndex, cultivarId))
    }

    function removeHeaderCultivar(cultivarIndex: number) {
        updateCultivarRows(arrayRemoveIndex(headerCultivar, cultivarIndex))
    }

    function updateCultivarRows(cultivars: number[]) {
        setHeaderCultivar(cultivars)
        setLeafData(l => {
            if (!l.selected)
                return l;
            return {...l, cultivars: cultivars};
        })
    }

    function changeCheckbox(index: number, v: boolean) {
        props.setData(props.leafs.map((l, i) => i===index ? {...l, selected: v} : l))
        if (!props.leafs.some(l => l.selected)) setSplit(false)
        setLeafDataIndex(index, {selected: v})
    }
    
    function updateSelectAll(v: boolean) {
        setLeafData(l => ({...l, selected: v}))
        props.setData(props.leafs.map(l => ({...l, selected: v})))
    }

    return <div> 
        <table className="w-full">
        <thead>
        <tr className='bg-gray-100 '>
            <th className="p-2 text-xs align-bottom">
                <div className='text-center'>
                    <div>Select</div>
                    <div className="text-center">
                        <CheckBox checked={props.leafs.every(l => l.selected)}
                                  onChange={v => updateSelectAll(v)}/>
                    </div>
                </div>
            </th>
            <th className="p-2 text-xs align-bottom">Sample Number</th>
            <th className="p-2 text-xs align-bottom">Block</th>
            <th className="p-2 text-xs align-bottom">
                <div>Crop</div>
                <SelectNumber options={props.data.crops} textFunc={c => c.name} valueFunc={c => c.id}
                              value={headerCrop} onChange={v => updateHeaderCrop(v)}/>
            </th>
            <th className="text-xs align-bottom">Actions</th>
        </tr>
        </thead>
        <tbody>
        {validation.items.map((leaf, index) =>
            <tr className={classNames(index % 2 === 0 ? '' : 'bg-gray-100', 'group hover:bg-gray-200 cursor-pointer')}
                key={index}>
                <td className={classNames("table-data text-center", leaf.data.delete ? 'inactive' : '')}>
                    <div className='flex-col items-end'>
                        <CheckBox checked={leaf.data.selected || false} onChange={v => changeCheckbox(index, v)}/>
                    </div>
                </td>
                <td className={classNames("table-data", leaf.data.delete ? 'inactive' : '')}>
                    <Input 
                        className={leaf.rules.sampleNumber ? '' : 'border-1 border-red-600'} 
                        value={leaf.data.sampleNumber} 
                        change={v => setLeafDataIndex(index, {sampleNumber: v})}/>
                    {leaf.data.notes}
                </td>
                <td className={classNames("table-data", leaf.data.delete ? 'inactive' : '')}>
                    <Input
                        className={leaf.rules.block ? '' : 'border-1 border-red-600'}
                        value={leaf.data.blockNumber} 
                        change={v => setLeafDataIndex(index, {blockNumber: v})}/>
                </td>
                <td className={classNames("table-data", leaf.data.delete ? 'inactive' : '')}>
                    <SelectNumber
                        className={leaf.rules.crop  ? '' : 'border-1 border-red-600'}
                        options={props.data.crops} 
                        textFunc={c => c.name} 
                        valueFunc={c => c.id}
                        value={leaf.data.cropId} 
                        onChange={v => setLeafDataIndex(index, {cropId: v})}/>
                </td>
                {props.data.tests.map((test, i) =>
                    <td key={`${test.key}-${leaf.data.testId}-test`} className={classNames(  "odd:bg-gray-100 even:bg-white text-2xs")}>
                        <input type="radio" className={classNames(test.key == "Unknown" ? "bg-red-500" : "bg-primary-600")} key={`${test.key}-${leaf.data.testId}-test`}
                               value={test.key} checked={leaf.data.test == test.key}
                               onChange={() => setLeafDataIndex(index, {test: test.key})}
                        />
                    </td>
                )}

                <td className="table-data">
                    {leaf.data.delete ? <div className="btn-sm btn-primary"
                                        onClick={() => setLeafDataIndex(index, {delete: false})}>+</div> : null}
                    {!leaf.data.delete ? <div className="btn-sm btn-error"
                                         onClick={() => setLeafDataIndex(index, {delete: true})}>-</div> : null}
                </td>
            </tr>)}
        </tbody>
    </table>
    </div>
}

export default SubmissionTable;