import React, {useContext, useEffect, useState} from "react";
import {ColumnMatcher, parseCSVFile} from "../CSVParse";
import {produce} from "immer";
import FarmerValues from "../../controllers/FarmerValues";
import AppContext from "../../appContext";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import FarmersSampleController from "../../controllers/FarmersSampleController";
import {useValidation} from "../../validation";
import ManualFarmersSampleResult from "../../controllers/ManualFarmersSampleResult";
import {showSuccessOrFailed} from "../../Snacks";
import Papa from "papaparse";
import {saveAs} from "file-saver";
import {asOptionalRealNumber} from "../../controllers/helper";
import ManualFileUploader from "../../components/ManualFileUploader";
import FileUploader from "../../components/FileUploader";
import NumberNullable from "../../components/NumberNullable";
import CheckBox from "../../components/CheckBox";
import Input from "../../components/Input";

type ValueKeys = keyof FarmerValues;

interface ValueEntry {
    code: string,
    name: string,
    unit: string
}

const labEntryMappings: Record<ValueKeys, ValueEntry> = {
    caAndMgDivK: {unit: '%', name: '(Ca+Mg) / K', code: '(Ca+Mg) / K'},
    caPerc: {unit: '%', name: 'Ca', code: 'Ca'},
    caToMg: {
        unit: '%', name: 'Ca:Mg', code: 'Ca:Mg'
    },
    density: {unit: 'g/cm3', name: 'Density', code: 'Density'},
    kPerc: {unit: '%', name: 'K', code: 'K'},
    mgPerc: {unit: '%', name: 'Mg', code: 'Mg'},
    mgToK: {
        unit: '%', name: 'Mg:K', code: 'Mg:K'
    },
    naPerc: {unit: '%', name: 'Na', code: 'Na'},
    naToK: {
        unit: '%', name: 'Na:K', code: 'Na:K'
    },
    pBray: {unit: 'ppm', name: 'P Bray', code: 'P Bray'},
    sAmAC: {unit: 'ppm', name: 'S Am AC', code: 'S Am AC'},
    phH2O: {unit: '', name: 'pH (H2O)', code: 'pH'},
    phKcl: {unit: '', name: 'pH (KCL)', code: 'pHKCL'},

    k: {unit: 'mg/kg', name: 'K', code: 'K(B)'},
    ca: {unit: 'mg/kg', name: 'Ca', code: 'Ca(B)'},
    mg: {unit: 'mg/kg', name: 'Mg', code: 'Mg(B)'},
    na: {unit: 'mg/kg', name: 'Na', code: 'Na(B)'},
    fe: {unit: 'mg/kg', name: 'Fe', code: 'Fe(B)'},
    mn: {unit: 'mg/kg', name: 'Mn', code: 'Mn(B)'},
    zn: {unit: 'mg/kg', name: 'Zn', code: 'Zn(B)'},
    cu: {unit: 'mg/kg', name: 'Cu', code: 'Cu(B)'},
    cWb: {unit: '%', name: 'C WB', code: 'C WB'},
    som: {unit: '%', name: 'SOM', code: 'SOM'},
    exchAcidKcl: {unit: 'cmol(+)/kg', name: 'Exch Acid KCL', code: 'Exch Acid KCL'},
    tValueAmAc: {unit: 'cmol(+)/kg', name: 'T-Value AmAc', code: 'T-Value AmAc'},
    acidSatAmAc: {unit: '%', name: 'Acid Sat AmAc', code: 'Acid Sat AmAc'},
    sValueAmAC: {unit: 'cmol(+)/kg', name: 'S-Value AmAC', code: 'S-Value AmAC'},
}

function matchArray(...array: string[]): (key: string) => boolean {
    return key => array.includes(key.toLowerCase().trim());
}

const ManualFarmersSampleResultData: ColumnMatcher<ManualFarmersSampleResult>[] = [
    {
        match: matchArray('sample number', 'sample', 'samplenumber', 'reference'),
        populate: (value, entry) => entry.sampleNumber = value
    },
    {
        match: matchArray('lab number', 'lab', 'labnumber', 'lab no'),
        populate: (value, entry) => {
            entry.labNumber = value;
        }
    },
    {
        match: matchArray('ph kcl'),
        populate: (value, entry) => entry.values.phKcl = asOptionalRealNumber(value)
    },
    {
        match: matchArray('ph h2o'),
        populate: (value, entry) => entry.values.phH2O = asOptionalRealNumber(value)
    },
    {
        match: matchArray('p bray', 'pbray', 'p bray i'),
        populate: (value, entry) => entry.values.pBray = asOptionalRealNumber(value)
    },
    {
        match: matchArray('k', 'k amac'),
        populate: (value, entry) => entry.values.k = asOptionalRealNumber(value)
    },
    {
        match: matchArray('na', 'na amac'),
        populate: (value, entry) => entry.values.na = asOptionalRealNumber(value)
    },
    {
        match: matchArray('ca', 'ca amac'),
        populate: (value, entry) => entry.values.ca = asOptionalRealNumber(value)
    },
    {
        match: matchArray('mg', 'mg amac'),
        populate: (value, entry) => entry.values.mg = asOptionalRealNumber(value)
    },
    {
        match: matchArray('density', 'dens.'),
        populate: (value, entry) => entry.values.density = asOptionalRealNumber(value)
    },
    {
        match: matchArray('s am ac', 'sam ac', 's amac'),
        populate: (value, entry) => entry.values.sAmAC = asOptionalRealNumber(value)
    },
    {
        match: matchArray('ca perc', 'ca%', 'ca% amac'),
        populate: (value, entry) => entry.values.caPerc = asOptionalRealNumber(value)
    },
    {
        match: matchArray('mg perc', 'mg%', 'mg% amac'),
        populate: (value, entry) => entry.values.mgPerc = asOptionalRealNumber(value)
    },
    {
        match: matchArray('k perc', 'k%', 'k% amac'),
        populate: (value, entry) => entry.values.kPerc = asOptionalRealNumber(value)
    },
    {
        match: matchArray('na perc', 'na%', 'na% amac'),
        populate: (value, entry) => entry.values.naPerc = asOptionalRealNumber(value)
    },
    {
        match: matchArray('ca to mg', 'ca:mg', 'ca:mg amac'),
        populate: (value, entry) => entry.values.caToMg = asOptionalRealNumber(value)
    },
    {
        match: matchArray('ca and mg div k', 'ca and mg / k', '(ca+mg)/k amac'),
        populate: (value, entry) => entry.values.caAndMgDivK = asOptionalRealNumber(value)
    },
    {
        match: matchArray('mg to k', 'mg:k', 'mg:k amac'),
        populate: (value, entry) => entry.values.mgToK = asOptionalRealNumber(value)
    },
    {
        match: matchArray('zn dtpa'),
        populate: (value, entry) => entry.values.zn = asOptionalRealNumber(value)
    },
    {
        match: matchArray('cu dtpa'),
        populate: (value, entry) => entry.values.cu = asOptionalRealNumber(value)
    },
    {
        match: matchArray('mn dtpa'),
        populate: (value, entry) => entry.values.mn = asOptionalRealNumber(value)
    },
    {
        match: matchArray('fe dtpa'),
        populate: (value, entry) => entry.values.fe = asOptionalRealNumber(value)
    },
    {
        match: matchArray('na to k', 'na:k', 'na:k amac'),
        populate: (value, entry) => entry.values.naToK = asOptionalRealNumber(value)
    },
    {
        match: matchArray('t-value amac'),
        populate: (value, entry) => entry.values.tValueAmAc = asOptionalRealNumber(value)
    },
    {
        match: matchArray('acid sat. amac'),
        populate: (value, entry) => entry.values.acidSatAmAc = asOptionalRealNumber(value)
    },
    {
        match: matchArray('s-value amac'),
        populate: (value, entry) => entry.values.sValueAmAC = asOptionalRealNumber(value)
    },
    {
        match: matchArray('exch acid kcl'),
        populate: (value, entry) => entry.values.exchAcidKcl = asOptionalRealNumber(value)
    },
    {
        match: matchArray('c wb'),
        populate: (value, entry) => entry.values.cWb = asOptionalRealNumber(value)
    },
    {
        match: matchArray('som'),
        populate: (value, entry) => entry.values.som = asOptionalRealNumber(value)
    },
]

const keys: (ValueKeys)[] = Object.keys(labEntryMappings) as unknown as (ValueKeys)[];

function BuildManualFarmersSampleResultString(sampleNumber: string): ManualFarmersSampleResult {
    return {
        sampleNumber,
        na: false,
        labNumber: '',
        values: {
            mn: null,
            na: null,
            phKcl: null,
            phH2O: null,
            pBray: null,
            k: null,
            ca: null,
            mg: null,
            density: null,
            sAmAC: null,
            caPerc: null,
            mgPerc: null,
            kPerc: null,
            naPerc: null,
            caToMg: null,
            caAndMgDivK: null,
            mgToK: null,
            naToK: null,
            zn: null,
            cu: null,
            fe: null,
            tValueAmAc: null,
            acidSatAmAc: null,
            sValueAmAC: null,
            exchAcidKcl: null,
            cWb: null,
            som: null
        }
    }
}

function interfaceSome<T extends object>(type: T, predicate: (value: T[keyof T]) => boolean): boolean {
    return Object.values(type).some(predicate);
}

const FarmerManualLabResult: React.FC<{
    submissionId: number;
    onClose: () => void;
}> = (props) => {
    const context = useContext(AppContext);
    const warningState = useWarningState(null);
    const [rows, setRows] = useState<ManualFarmersSampleResult[]>([]);
    const [showUpload, setShowUpload] = useState(false);
    const [rowsNotUploaded, setRowsNotUploaded] = useState(0);

    useEffect(() => {
        // get initial sample number 
        // populate table
        FarmersSampleController.getSampleNumbersForSubmission(props.submissionId)
            .then(resp => {
                setRows(resp.data.map(sampleNumber => BuildManualFarmersSampleResultString(sampleNumber)))
            })
            .catch(err => context.showSnack(<>Could not get samples.</>))
    }, [])

    const validation = useValidation({
        everyRow: () => rows.every(row => {
            return row.na
                ? true
                : interfaceSome(row.values, f => f != null)
                    ? true
                    : row.labNumber.length > 0
        })
    })

    function save() {
        if (!validation.validate()) return

        const call = FarmersSampleController.updateManualSubmission({
            submissionId: props.submissionId,
            manualResult: rows
        })
        showSuccessOrFailed(context, call)
            .then(() => {
                setRowsNotUploaded(0)
                props.onClose()
            })
            .catch(e =>
                context.showSnack(<>Could not save result.</>)
            )
    }

    function downloadCSV() {
        const flat = rows.map(row => (
            {
                "Sample Number": row.sampleNumber,
                "Lab Number": row.labNumber,
                ...keys.reduce((acc, key) => {
                    acc[labEntryMappings[key].name as keyof FarmerValues] = row.values[key] || 0 // should it have 0?
                    return acc
                }, {} as Record<ValueKeys, number>),
            }
        ))
        const csvData = Papa.unparse(flat);
        const blob = new Blob([csvData], {type: 'text/csv'});


        saveAs(blob, 'data.csv');
    }

    function BuildManualFarmersSampleResult(index: number): ManualFarmersSampleResult {
        return {
            sampleNumber: rows[index]?.sampleNumber ?? '',
            na: false,
            labNumber: '',
            values: {
                phKcl: null,
                phH2O: null,
                pBray: null,
                k: null,
                na: null,
                ca: null,
                mg: null,
                density: null,
                sAmAC: null,
                caPerc: null,
                mgPerc: null,
                kPerc: null,
                naPerc: null,
                caToMg: null,
                caAndMgDivK: null,
                mgToK: null,
                naToK: null,
                zn: null,
                mn: null,
                cu: null,
                fe: null,
                tValueAmAc: null,
                acidSatAmAc: null,
                sValueAmAC: null,
                exchAcidKcl: null,
                cWb: null,
                som: null
            }
        }
    }

    function showWarningIfValidated() {
        if (!validation.validate()) return

        warningState.show("Are you sure you want " +
            " submit this manual submission?", null)
    }

    function csvUpload(file: File) {
        const reader = new FileReader()
        reader.onload = e => {
            const text: string = e.target?.result as string
            magicPaste(text);
        }
        reader.readAsText(file)
    }

    function magicPaste(text: string) {
        let rowsNotFound = 0;
        const entries = parseCSVFile<ManualFarmersSampleResult>(text, index => BuildManualFarmersSampleResult(index), ManualFarmersSampleResultData)
        
        setRows(r => r.map((row, index) => {
            const ent = entries.find(e => e.sampleNumber.toLowerCase().trim() == row.sampleNumber.toLowerCase().trim())
            if (ent) {
                return {...ent, sampleNumber: row.sampleNumber}
            }
            rowsNotFound++;
            return row
        }))
        setRowsNotUploaded(rowsNotFound)
    }

    // from excel rows by \n, columns by \t
    function formatClipboard(clipboard: string): string[][] {
        return clipboard.split('\n').map(r => r.split('\t'))
    }

    function handlePaste(event: React.ClipboardEvent<HTMLInputElement>) {
        const text = event.clipboardData.getData('text');
        if (!text || text === '') {
            return;
        }

        const data = formatClipboard(text);

        setRows(currentRows => currentRows.map((row, index) => {
            const rowData = data[index];
            if (rowData) {
                return {
                    ...row,
                    labNumber: rowData[0] ? rowData[0] : row.labNumber,
                    values: {
                        sAmAC: asOptionalRealNumber(rowData[0] ?? ""),
                        pBray: asOptionalRealNumber(rowData[1] ?? ""),
                        k: asOptionalRealNumber(rowData[2] ?? ""),
                        na: asOptionalRealNumber(rowData[3] ?? ""),
                        ca: asOptionalRealNumber(rowData[4] ?? ""),
                        mg: asOptionalRealNumber(rowData[5] ?? ""),
                        density: asOptionalRealNumber(rowData[6] ?? ""),
                        phH2O: asOptionalRealNumber(rowData[7] ?? ""),
                        phKcl: asOptionalRealNumber(rowData[8] ?? ""),
                        caPerc: asOptionalRealNumber(rowData[9] ?? ""),
                        mgPerc: asOptionalRealNumber(rowData[10] ?? ""),
                        kPerc: asOptionalRealNumber(rowData[11] ?? ""),
                        naPerc: asOptionalRealNumber(rowData[12] ?? ""),
                        caToMg: asOptionalRealNumber(rowData[13] ?? ""),
                        caAndMgDivK: asOptionalRealNumber(rowData[14] ?? ""),
                        mgToK: asOptionalRealNumber(rowData[15] ?? ""),
                        naToK: asOptionalRealNumber(rowData[16] ?? ""),
                        zn: asOptionalRealNumber(rowData[17] ?? ""),
                        mn: asOptionalRealNumber(rowData[18] ?? ""),
                        fe: asOptionalRealNumber(rowData[19] ?? ""),
                        cu: asOptionalRealNumber(rowData[20] ?? ""),
                        tValueAmAc: asOptionalRealNumber(rowData[21] ?? ""),
                        acidSatAmAc: asOptionalRealNumber(rowData[22] ?? ""),
                        sValueAmAC: asOptionalRealNumber(rowData[23] ?? ""),
                        exchAcidKcl: asOptionalRealNumber(rowData[24] ?? ""),
                        cWb: asOptionalRealNumber(rowData[25] ?? ""),
                        som: asOptionalRealNumber(rowData[26] ?? "")
                    } as FarmerValues
                } as ManualFarmersSampleResult;
            }
            return row;
        }));
        event.preventDefault();
    }

    function uploadFiles() {
        setShowUpload(true)
    }

    return <div className={""}>
        <div className="overflow-x-auto pb-2">
            <table className="m-2o">
                <thead>
                <tr>
                    <td className="pr-2">Sample Number</td>
                    <td className="pr-2">Lab Number</td>
                    {keys.map(key =>
                        <td key={labEntryMappings[key].name + labEntryMappings[key].unit + labEntryMappings[key].code}
                            className="pr-2 text-xs whitespace-nowrap">
                            <div>
                                {labEntryMappings[key].name}
                            </div>
                            <div>
                                {labEntryMappings[key].unit}
                            </div>
                        </td>)}
                    <td className="pr-2">N/A</td>
                </tr>
                </thead>

                <tbody>

                {rows.map((result, index) =>
                    <tr key={result.sampleNumber + 'input'} className="text-gray-500">
                        <td className="pr-2 w-[10%] whitespace-nowrap">{result.sampleNumber}</td>
                        <td className="pr-2 w-[10%] ">
                            <Input onPaste={handlePaste}
                                   disabled={rows[index]!.na}
                                   className="w-full" value={result.labNumber}
                                   change={e => {
                                       setRows(produce(rows, draft => {
                                           draft[index]!.labNumber = e;
                                       }));
                                   }}/>
                        </td>

                        {keys.map(key => <td key={result.sampleNumber + 'number' + key} className="pr-2">
                            <NumberNullable value={result.values[key]}
                                            className={"min-w-[40px]"}
                                            disabled={rows[index]!.na}
                                            onPaste={handlePaste}
                                            change={val => {
                                                setRows(produce(rows, draft => {
                                                    draft[index]!.values[key] = val;
                                                }));

                                            }}/>
                        </td>)}
                        <td>
                            <CheckBox checked={rows[index]!.na} onChange={val => setRows(produce(rows, draft => {
                                draft[index]!.na = val
                            }))}/>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>

        <div className="flex-1 flex justify-between items-center ">
            <div className='flex items-end'>
                <div className='btn bg-primary-500 mx-2' onClick={() => downloadCSV()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"/>
                    </svg>
                </div>

                <div className='btn bg-primary-500 mx-2'>
                    <FileUploader onChange={file => csvUpload(file)} fileTypes=".csv">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"/>
                        </svg>
                    </FileUploader>
                </div>

                <div className='btn bg-primary-500 mx-2' onClick={e => {
                    // get clipboard data
                    navigator.clipboard.readText().then(text => {
                        magicPaste(text);
                    })
                }}>
                    <div className="flex">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6">
                            <path
                                d="M5 5h2v1c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V5h2v5h2V5c0-1.1-.9-2-2-2h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h5v-2H5V5zm7-2c.55 0 1 .45 1 1s-.45 1-1 1s-1-.45-1-1s.45-1 1-1z"
                                fill="currentColor"></path>
                            <path
                                d="M22.3 20.9l-2-2c.58-1.01.95-2.23.51-3.65c-.53-1.72-2.04-3.05-3.84-3.22a4.498 4.498 0 0 0-4.95 4.95c.18 1.79 1.5 3.31 3.22 3.84c1.43.44 2.64.07 3.65-.51l2 2c.39.39 1.01.39 1.4 0s.4-1.02.01-1.41zM16.5 19c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5z"
                                fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                <div className='btn bg-primary-500 mx-2' onClick={() => uploadFiles()}>
                    Upload files
                </div>


                {
                    rowsNotUploaded > 0
                        ? <div className='text-sm text-red-500'>
                            ({rowsNotUploaded} rows not uploaded)
                        </div>
                        : null
                }
            </div>

            <div className='flex justify-between sm:justify-end items-center'>
                {
                    !validation.rules.everyRow
                        ? <div className="text-xs text-red-500 pr-2">(Please ensure that each row contains at least one
                            entry or is marked as N/A)</div>
                        : null
                }
                <div className="btn btn-primary my-2" onClick={() => showWarningIfValidated()}>Save</div>
            </div>
        </div>

        <WarningPopup state={warningState} onYes={save}/>

        <ManualFileUploader show={showUpload}
                            setShow={setShowUpload}
                            submissionId={props.submissionId}/>
    </div>
}

export default FarmerManualLabResult;

