import {Tab} from "@headlessui/react";
import React, {Fragment, useContext, useEffect, useMemo, useRef, useState} from "react";
import AppContext from "../appContext";
import {classNames, wrapLoader} from "../wrapper";
import PagedSearchTable, {PagedTableFunctions, TableColumn} from "../components/PagedSearchTable";
import {dateFormat} from "../date";
import PendingSubmissionQuery from "../controllers/PendingSubmissionQuery";
import SubmissionDisplay from "../controllers/SubmissionDisplay";
import CheckBox from "../components/CheckBox";
import WarningPopup, {useWarningState} from "../components/WarningPopup";
import {showSuccessOrFailed} from "../Snacks";
import Dialog from "../components/Dialog";
import SubmissionPrepare from "./submission/SubmissionPrepare";
import Data from "../controllers/Data";
import {recordToArray} from "../array";
import PagedTable from "../components/PagedTable";
import Reports from "./submission/Report";
import {arrayPush, arrayRemoveItem, useStateSetterArray} from "../immutableState";
import Success from "../components/Success";
import SubmissionController from "../controllers/SubmissionController";
import PreReportData from "../controllers/PreReportData";
import PaginationResponse from "../controllers/PaginationResponse";
import {AxiosResponse} from "axios";
import PaginationRequestSearch from "../controllers/PaginationRequestSearch";
import Input from "../components/Input";
import Failed from "../components/Failed";
import {useValidation} from "../validation";
import ManualLabResult from "./ManualLabResult";
import Lab from "../controllers/Lab";
import PermissionEnum from "../controllers/PermissionEnum";
import File from "../controllers/File";
import CustomSnack from "../components/CustomSnack";
import {PendingSubmissions, PendingSubmissionsRef} from "./submission/PendingSubmissions";
import SubmissionReportDataLeafResponseApi from "../controllers/SubmissionReportDataLeafResponseApi";

export const DownloadSvg = () => {
    return <svg viewBox="0 0 24 24"
                fill="white"
                className="w-6 h-6">
        <path
            fillRule="evenodd"
            d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
            clipRule="evenodd" />
    </svg>
}

const FileSvg = () => {
    return <svg className="h-10 w-10 text-primary m-2"  viewBox="0 0 512 512">
        <path d="M448 64H64L32 256v192h448V256zm-12 192H320a64 64 0 0 1-128 0H76l22-150h316z" fill="#8ABE3C"></path>
    </svg>
}

function buttonClass(selected: boolean): string {
    return classNames('w-full rounded-lg text-sm font-medium leading-5 text-primary-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-400 focus:outline-none focus:ring-2', selected
        ? 'bg-white shadow'
        : 'text-primary-100 hover:bg-white/[0.12] hover:text-white');
}

const Submissions: React.FC = () => {
    const context = useContext(AppContext);

    return (
        <div className="bg-white p-2 shadow">

            <Tab.Group defaultIndex={-1}>
                <Tab.List className="flex space-x-1 rounded-xl bg-primary-500 p-1">
                    {
                        context.hasPermission(PermissionEnum.SubmissionsPrepare) ?
                            <Tab as={Fragment}>
                                {({ selected }) => (
                                    <button className={buttonClass(selected)}>
                                        Prepare
                                    </button>
                                )}
                            </Tab>
                            : null
                    }
                    {
                        context.hasPermission(PermissionEnum.SubmissionsWaitingForLab) ?
                            <Tab as={Fragment}>
                                {({ selected }) => (
                                    <button className={buttonClass(selected)}>
                                        Waiting for Lab
                                    </button>
                                )}
                            </Tab>
                            : null
                    }
                    {
                        context.hasPermission(PermissionEnum.SubmissionsResults) ?
                            <Tab as={Fragment}>
                                {({ selected }) => (
                                    <button className={buttonClass(selected)}>
                                        Results
                                    </button>
                                )}
                            </Tab>
                            : null
                    }
                    {
                        context.hasPermission(PermissionEnum.SubmissionsCompleted) ?
                            <Tab as={Fragment}>
                                {({ selected }) => (
                                    <button className={buttonClass(selected)}>
                                        Completed
                                    </button>
                                )}
                            </Tab>
                            : null
                    }
                </Tab.List>

                <Tab.Panels>
                    {
                        context.hasPermission(PermissionEnum.SubmissionsPrepare) ?
                        <Tab.Panel>
                            <Prepare />
                        </Tab.Panel>
                        : null
                    }
                    {
                        context.hasPermission(PermissionEnum.SubmissionsWaitingForLab) ?
                            <Tab.Panel>
                                <Waiting />
                            </Tab.Panel>
                            : null
                    }
                    {
                        context.hasPermission(PermissionEnum.SubmissionsResults) ?
                            <Tab.Panel>
                               <CompletedResults canPost={false} call={SubmissionController.pagedResults} />
                            </Tab.Panel>
                            : null
                    }
                    {
                        context.hasPermission(PermissionEnum.SubmissionsCompleted) ?
                            <Tab.Panel>
                                <CompletedResults canPost={true} call={SubmissionController.pagedCompleted} />
                            </Tab.Panel>
                            : null
                    }
                </Tab.Panels>
            </Tab.Group>

        </div>
    )
}

const Prepare: React.FC = () => {

    const context = useContext(AppContext);
    const pendingSubmissionsRef = useRef<PendingSubmissionsRef>(null);
    const [showPrepare, setShowPrepare] = useState(false);
    const [prepareData, setPrepareData] = useState<PendingSubmissionQuery>();

    function newShow() {
        setPrepareData(undefined);
        setShowPrepare(true);
    }
    function show(row: PendingSubmissionQuery) {
        setPrepareData(row);
        setShowPrepare(true);
    }

    function archiveLeafs(dataIds: number[]) {
        showSuccessOrFailed(context, SubmissionController.archiveLeafs({
            ids: dataIds
        })).then(() => {
            pendingSubmissionsRef.current?.refresh();
        })
    }

    return <>
        <PendingSubmissions ref={pendingSubmissionsRef} call={SubmissionController.paged} prepare={(item) => show(item)} archive={dataIds => archiveLeafs(dataIds)} newSubmission={newShow}/>

        <Dialog title="Prepare Submission" body={<SubmissionPrepare close={() => {
            setShowPrepare(false)
            pendingSubmissionsRef.current?.refresh();
        }} dataIds={prepareData?.dataIds ?? []} />}
                show={showPrepare} setShow={(state) => {
            setShowPrepare(state)
            pendingSubmissionsRef.current?.refresh();
        }} />
    </>
}

const Print: React.FC<{ id: number }> = (props) => {
    const [labResults, setLabResults] = useState('')

    useEffect(() => {
        SubmissionController.print({ id: props.id }).then(resp => {
            if (resp.data.length > 0)
                setLabResults(resp.data)
            else
                setLabResults('No lab results available')
        })

    }, []);

    return <div className="px-2" dangerouslySetInnerHTML={{ __html: labResults }}></div>
}

const LinkJobId: React.FC<{ submissionId: number, close: () => void }> = (props) => {
    const [jobId, setJobId] = useState<string>("");
    const context = useContext(AppContext);
    const validation = useValidation({
        jobId: () => jobId.length > 0
    })

    function SubmitId(item: string) {
        if (!validation.validate()) {
            return;
        }
        wrapLoader(context, SubmissionController.manualLabId({
            labJobId: parseInt(item),
            submissionId: props.submissionId
        }), (data) => {
            if (data) {
                props.close()
                context.showSnack(<Success title={"Success"} />)
            } else {
                context.showSnack(<Failed title={"Can't find lab id."} />)
            }
        })
    }

    return <div className="p-2">
        <span>{!validation.rules.jobId ? <div className="text-error-600">Required</div> : null}</span>
        <Input value={jobId}
            change={val => setJobId(val)} />

        <div className="text-right p-2 border-t sticky bottom-0 bg-white">
            <button onClick={props.close} className="btn btn-error">Cancel</button>
            <div onClick={() => SubmitId(jobId)} className="btn btn-primary">Submit</div>
        </div>
    </div>
}

function getSubmissionData(item: SubmissionDisplay) {
    return `${item.lab}${item.lab != Lab.Manual ? " - " + item.submissionWorkOrder: ""}`;
}

const Waiting: React.FC = () => {
    const context = useContext(AppContext)
    const [printData, setPrintData] = useState<{ show: boolean, id: number }>({ id: 0, show: false });
    const [addLabId, setAddLabId] = useState<{ show: boolean, submissionId: number }>({ submissionId: 0, show: false });
    const [manualData, setManualData] = useState<{ show: boolean, submissionId: number }>({ submissionId: 0, show: false });
    const confirmation = useWarningState<number>(0);
    const [jobIdCheck, setJobIdCheck] = useState(false);
    const pagedTableRef = useRef<PagedTableFunctions<SubmissionDisplay>>();

    function print(item: SubmissionDisplay) {
        setPrintData({ id: item.id, show: true })
    }
    function manualSubmit() {
        setManualData({submissionId: 0 ,show: false})
        pagedTableRef.current?.refresh();
    }
    function manual(item: SubmissionDisplay) {
        setManualData({ submissionId: item.id, show: true })
    }
    function clickLabId(item: SubmissionDisplay) {
        setAddLabId({ submissionId: item.id, show: true })
    }

    function warning(item: SubmissionDisplay) {
        confirmation.show(`Are you sure you want to delete row ${item.clientName}?`, item.id);
    }

    function deleteRow(id: number) {
        SubmissionController.delete({id}).then(resp =>{
            if (!resp.data) {
                // cannot delete row with Lab/ job ids
                setJobIdCheck(true);
                return;

            }
            showSuccessOrFailed(context, SubmissionController.delete({id}) ).then(resp=> {
                confirmation.hide()
                pagedTableRef.current?.refresh();
            });
        });

    }

    function closeLabId() {
        setAddLabId({ submissionId: 0, show: false })
        pagedTableRef.current?.refresh();
    }

    return (<>

            <Dialog title="May not delete row"
                    body ={<div className="p=5 m-3">May not delete row with Lab Job number</div>}
                    show = {jobIdCheck}
                    setShow={() =>setJobIdCheck(false)}/>

            <Dialog title="Lab Results"
                    body={<Print id={printData.id}/>} show={printData.show}
                    setShow={() => setPrintData({id: 0, show: false})}/>

            <Dialog title="Job Id"
                body={<LinkJobId submissionId={addLabId.submissionId} close={closeLabId} />} show={addLabId.show}
                setShow={closeLabId} />

            <Dialog title="Manual Submission"
                    show={manualData.show}
                    setShow={manualSubmit}>
                <ManualLabResult onClose={manualSubmit} submissionId={manualData.submissionId}/>
            </Dialog>

        <WarningPopup state={confirmation} onYes={deleteRow} />

        <PagedSearchTable componentRef={pagedTableRef} call={SubmissionController.pagedWaiting}
            keyExtractor={s => s.id} columns={[
                {
                    header: 'Id',
                    row: item => item.id,
                    classNames: item => item.error.length > 0 ? 'bg-red-200' : ''
                },
                {
                    header: 'Prepared',
                    row: item => dateFormat(item.prepared),
                    classNames: item => item.error.length > 0 ? 'bg-red-200' : ''
                },
                {
                    header: 'Submitted',
                    row: item => dateFormat(item.submitted),
                    classNames: item => item.error.length > 0 ? 'bg-red-200' : ''
                },
                {
                    header: 'Submission',
                    row: item => item.error.length > 0 ? item.error : getSubmissionData(item),
                    colspan: item => item.error.length > 0 ? 5 : 1,
                },
                {
                    header: 'Client (farm)',
                    row: item => <span className="table-data">{item.clientName} ({item.farmName})</span>,
                    colspan: item => item.error.length > 0 ? 0 : 1
                },
                {
                    header: 'Samples',
                    row: item => <span> {item.items} ({item.crop.join(', ')})</span>,
                    colspan: item => item.error.length > 0 ? 0 : 1
                },
                {
                    header: 'Lab Job / Work Numbers',
                    row: item => item.labJobId || item.labWorkOrder ?
                        <span> {item.labJobId} ({item.labWorkOrder})</span> : null,
                    colspan: item => item.error.length > 0 ? 0 : 1
                },
                {
                    header: <div className='text-right'>Actions</div>,
                    row: item => <div className='text-right'>
                        {item.lab == Lab.Manual
                            ?
                            <div className="btn-primary btn-sm" onClick={() => manual(item)}>
                                    Manual
                            </div>


                        :item.labJobId || item.labWorkOrder
                            ? null
                            : <div className="btn-primary btn-sm" onClick={() => clickLabId(item)}>
                                    Manual Link
                                </div>
                            }
                        <div className="btn-primary btn-sm" onClick={() => print(item)}>
                            print
                        </div>
                        { !item.labJobId && context.hasPermission(PermissionEnum.SubmissionsDelete)
                            ? <div className="btn-error btn-sm" onClick={() => warning(item)}>
                                x
                            </div>
                            : null
                        }
                    </div>
                },
            ]} />
    </>
    );
}

const LabResult: React.FC<{ id: number, data: Data[] }> = (props) => {
    const data = props.data[0]!;
    const context = useContext(AppContext);

    function exportCsv() {
        window.location.href = SubmissionController.urls.exportResults({ id: props.id })
        context.showSnack(<Success title={"Starting download"} />)
    }

    const samples = recordToArray(data.sampleData, (key, item) => ({
        key,
        item: item
    }));

    return <div>
        <button className="m-2 btn btn-primary" onClick={exportCsv}>Export CSV</button>

        <div className="p-4 text-left">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Lab Name</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.lab_name}
                            </dd>
                        </div>

                        {samples.map(sample =>
                            <div key={sample.key} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                    <div className="table w-full">
                                        <div className="table-row">
                                            <div className="w-1/2 p-1 table-cell text-gray-800">Lab
                                                Number
                                            </div>

                                            <div className="w-1/2 p-1 table-cell text-gray-800 whitespace-nowrap">Sample
                                                Reference
                                            </div>
                                        </div>

                                        <div className="table-row">
                                            <div className="w-1/2 p-1 table-cell bg-">{sample.item.lab_number}</div>
                                            <div className="w-1/2 p-1 table-cell">{sample.item.sample_reference}</div>
                                        </div>
                                    </div>
                                </dt>

                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                                    <PagedTable data={sample.item.resultvalue} columns={[
                                        {
                                            header: 'Code',
                                            row: row => row.code
                                        },
                                        {
                                            header: 'Name',
                                            row: row => row.name
                                        },
                                        {
                                            header: 'Lab Result',
                                            row: row => row.lab_result
                                        },
                                        {
                                            header: 'Unit',
                                            row: row => row.unit
                                        }
                                    ]} />
                                </dd>

                            </div>)}
                    </dl>
                </div>
            </div>
        </div>
    </div>
}


const CompletedResults: React.FC<{ canPost: boolean, call: (request: PaginationRequestSearch) => Promise<AxiosResponse<PaginationResponse<SubmissionDisplay>, any>> }> = (props) => {
    const context = useContext(AppContext);

    const [printData, setPrintData] = useState<number | null>(null);
    const [reportData, setReportData] = useState<PreReportData<SubmissionReportDataLeafResponseApi> | null>(null);
    const [resultData, setResultData] = useState<Data[] | null>(null);
    const pagedTableRef = useRef<PagedTableFunctions<SubmissionDisplay>>()
    const [selectedReports, setSelectedReports, updateSelectedReports] = useStateSetterArray<number>([])
    const [id, setId] = useState(0);
    const [files, setFiles] = useState<File[]>([])

    const postMfwState = useWarningState<number>(0)

    function report(row: SubmissionDisplay) {
        setId(row.id);
        wrapLoader(context, SubmissionController.preReport({ ids: [row.id] }), data => {
            setReportData(data);
        })
    }

    function checkPostToMfw(row: SubmissionDisplay) {
        if (row.postedToMfw) {
            postMfwState.show("Are you sure you want to post to MFW again?", row.id)
        } else {
            postMfwState.show("Are you sure you want to post to MFW?", row.id)
        }
    }

    function postToMfw(id: number) {
        wrapLoader(context, SubmissionController.postToMfw({ id }), (data) => {
            if (data.status) {
                pagedTableRef.current?.refresh()
                context.showSnack(<Success title={"Success"} />)
            } else {
                context.showSnack(<Failed title={data.message ?? "Something went wrong"} />, 10000)
            }
        })
    }

    function results(row: SubmissionDisplay) {
        setId(row.id);
        wrapLoader(context, SubmissionController.getResults({ id: row.id }), data => {
            setResultData(data);
        })
    }

    function print(row: SubmissionDisplay) {
        setId(row.id);
        setPrintData(row.id);
    }

    function setSelectedList(val: number) {
        if (selectedReports.includes(val)) {
            setSelectedReports(arrayRemoveItem(selectedReports, val))
        } else {
            setSelectedReports(arrayPush(selectedReports, val))
        }
    }

    function combineReports() {
        wrapLoader(context, SubmissionController.preReport({ ids: selectedReports }), data => {
            setReportData(data);
        })
    }
    
    function getDocks(row: SubmissionDisplay) {
        const hideLoader = context.showLoader();
        SubmissionController.getDocs({id: row.id})
            .then(resp => {
                if (resp.data.length == 0) 
                    context.showSnack(<CustomSnack svg={<FileSvg/>} title={"No files found."} />)
                setFiles(resp.data)
            })
            .finally(hideLoader)
    }
    
    const options = (isManual: boolean) => {
        return isManual 
            ? Object.entries({
                results: "results",
                docs: "docs"
            })
            : Object.entries({
                results: "results",
                print: "print",
                docs: "docs"
            })
    }
    
    function selectOption(event: React.ChangeEvent<HTMLSelectElement>, item: SubmissionDisplay) {
        const value = event.target.value;
        if (value == "results") {
            results(item)
        } else if (value == "print") {
            print(item)
        } else if (value == "docs") {
            getDocks(item)
        }
    }
    const getActions = (item: SubmissionDisplay) => <div className="flex justify-end">
        {
            props.canPost && item.hasLocation
                ? <div className={classNames("btn-sm", item.postedToMfw ? "bg-gray-400" : "btn-primary")}
                       onClick={() => checkPostToMfw(item)}>
                    post to mfw
                </div>
                : null
        }

        <div className="btn-primary btn-sm " onClick={() => report(item)}>
            report
        </div>
        <select value={'empty'} 
                className="bg-center bg-no-repeat outline-none btn-primary btn-sm p-2 w-9 "
                style={{
                    appearance: "none",
                    backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' fill=\'none\' viewBox=\'0 0 24 24\' stroke-width=\'2\' stroke=\'white\' class=\'w-6 h-6\'><path stroke-linecap=\'round\' stroke-linejoin=\'round\' d=\'M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5\'/></svg>")' }}
                onChange={e => selectOption(e, item)}>
            <option value="empty" className="hidden" disabled={true}></option>
            {options(item.lab == Lab.Manual).map(([text, value]) => <option key={value} value={value}>{text}</option>)}
        </select>
    </div>

    const tableData = useMemo(() => {
        let tableData: TableColumn<SubmissionDisplay>[] = [{
            header: '',
            row: item => <CheckBox onChange={_ => setSelectedList(item.id)}
                checked={selectedReports.includes(item.id)} />
        },
        {
            header: 'Id',
            row: item => item.id
        },
        {
            header: 'Client (Farm)',
            row: item => <span>{item.clientName} ({item.farmName})</span>
        },
        {
            header: 'Samples',
            row: item => <span>{item.items} ({item.crop.join(', ')})</span>
        },
        {
            header: 'Submitted On / Completed On',
            row: item => <span>{dateFormat(item.submitted) || "_"} / {dateFormat(item.completed)}</span>
        }]

        if (props.canPost) {
            tableData.push(
                {
                    header: 'Date Posted To mfw',
                    row: item => <span>{dateFormat(item.postedToMfwDate, '%d %M %Y %H:%i') || "_"}</span>
                })
        }

        tableData.push({
            header: <div className='text-right'>Actions</div>,
            row: item => getActions(item)
        })
        return tableData;
    }, [props.canPost, selectedReports]);


    return <>
        <WarningPopup state={postMfwState} onYes={postToMfw}/>
        {printData != null ?
            <Dialog title="Lab Results"
                body={<Print id={printData} />} show={true}
                setShow={() => setPrintData(null)} />
            : null}

        {reportData != null ?
            <Dialog title="Report Settings"
                body={<Reports data={reportData} />} show={true}
                setShow={() => setReportData(null)} />
            : null}

        {resultData != null ?
            <Dialog title="Lab Result"
                body={<LabResult id={id} data={resultData} />} show={true}
                setShow={() => setResultData(null)} />
            : null}

        {selectedReports.length > 1
            ? <div className="btn-primary btn-sm m-2" onClick={() => combineReports()}>Combine Reports</div>
            : null}

        <PagedSearchTable componentRef={pagedTableRef} keyExtractor={r => r.id}
            call={props.call} columns={tableData} />
        
        <Dialog show={files.length > 0} setShow={() => setFiles([])} title="Files">
            {
                files.map(file => <div key={file.id} className="flex justify-between items-center m-2">
                    <div>{file.name}</div>
                    <a
                        href={"/upload/" + file.path}
                        download
                        className="flex items-center justify-between bg-primary text-white p-2 rounded-lg ml-3 hover:opacity-90"
                    >
                        <div className="mr-2">
                            Download
                        </div>
                        <DownloadSvg/>
                    </a>
                </div>)
            }
        </Dialog>
    </>
}


export default Submissions;
