import LeafController from "../../controllers/LeafController";
import React, {useContext, useRef, useState} from "react";
import AppContext from "../../appContext";
import LeafApiData from "../../controllers/LeafApiData";
import Success from "../../components/Success";
import PreLeafDisplayFlat from "../../controllers/PreLeafDisplayFlat";
import {useStateAjax, wrapLoader} from "../../wrapper";
import SubmissionDisplay from "../../controllers/SubmissionDisplay";
import LabController from "../../controllers/LabController";
import PagedSearchTable, {PagedTableFunctions, TableColumn} from "../../components/PagedSearchTable";
import {dateFormat} from "../../date";
import Dialog from "../../components/Dialog";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import LeafResultResponse from "../../controllers/LeafResultResponse";

function getValues(result: LeafResultResponse, name: string): string {
    return result.result?.find(r => r.name === name)?.lab_result ?? ''
}

const resultCol: | TableColumn<LeafResultResponse>[] = [
    {
        header: 'Lab',
        row: (item) => <span>{item.lab}</span>
    }, {
        header: 'Sample Number',
        row: (item) => <span>{item.sampleNumber}</span>
    }, {
        header: 'Crop',
        row: (item) => <span>{item.crop}</span>
    }, {
        header: <>
            Client (Farm)
            <div className="text-xs whitespace-nowrap text-gray-500">
                Block
            </div>
        </>,
        row: (item) =>
            <>
                {item.clientName} ({item.farmName})
                <div className="text-xs whitespace-nowrap text-gray-500">
                    {item.blockNumber}
                </div>
            </>
    }, {
        header: 'S (%)',
        row: (item) => <span>{getValues(item, "S")}</span>
    }, {
        header: 'P (%)',
        row: (item) => <span>{getValues(item, "P")}</span>
    }, {
        header: 'Na (mg/kg)',
        row: (item) => <span>{getValues(item, "Na")}</span>
    }, {
        header: 'N (%)',
        row: (item) => <span>{getValues(item, "N")}</span>
    }, {
        header: 'Mo (mg/kg)',
        row: (item) => <span>{getValues(item, "Mo")}</span>
    }, {
        header: 'Mn (mg/kg)',
        row: (item) => <span>{getValues(item, "Mn")}</span>
    }, {
        header: 'Mg (%)',
        row: (item) => <span>{getValues(item, "Mg")}</span>
    }, {
        header: 'K (%)',
        row: (item) => <span>{getValues(item, "K")}</span>
    }, {
        header: 'Fe (mg/kg)',
        row: (item) => <span>{getValues(item, "Fe")}</span>
    }, {
        header: 'Cu (mg/kg)',
        row: (item) => <span>{getValues(item, "Cu")}</span>
    }, {
        header: 'Ca (%)',
        row: (item) => <span>{getValues(item, "Ca")}</span>
    }, {
        header: 'B (mg/kg)',
        row: (item) => <span>{getValues(item, "B")}</span>
    }, {
        header: 'Zn (mg/kg)',
        row: (item) => <span>{getValues(item, "Zn")}</span>
    }, {
        header: 'Ni (mg/kg)',
        row: (item) => <span>{getValues(item, "Ni")}</span>
    },
]


const LeafsTab: React.FC<{
    archived: boolean,
    notCompleted: boolean,
    results: boolean
}> = (props) => {
    const context = useContext(AppContext);

    const leafTableRef = useRef<PagedTableFunctions<PreLeafDisplayFlat>>()
    const resultTableRef = useRef<PagedTableFunctions<LeafResultResponse>>()

    const [showImage, setShowImage] = useState<boolean>(false)
    const [imageData, setImageData] = useState<string>('')
    const [showJson, setShowJson] = useState(false)
    const confirmationWarningState = useWarningState<number>(0)
    const restoreWarningState = useWarningState<number>(0)
    const [rowId, setRowId] = useState(-1)
    const [archiveState, setArchiveState] = useState(false)
    const [jsonData, setJsonData] = useState<LeafApiData>()

    const [selected, setSelected] = useState<number>(-1)
    const [smallLoader, setSmallLoader] = useState<boolean>(false)

    const [labs, setLabs] = useStateAjax(LabController.all, [])

    function archive(id: number, state: boolean) {
        LeafController.archive({id, archive: state}).then(resp => {
            confirmationWarningState.hide();
            leafTableRef.current?.refresh();
        }).then(() => {
            context.showSnack(<Success title={"Successfully"}/>)
        }).catch(() => {
            context.showSnack(<Success title={"Failed"}/>)
        })
    }

    function restore(id: number, state: boolean) {
        LeafController.archive({id, archive: state}).then(() => {
            restoreWarningState.hide()
            leafTableRef.current?.refresh();
        }).then(() => {
            context.showSnack(<Success title={"Successfully"}/>)
        }).catch(() => {
            context.showSnack(<Success title={"Failed"}/>)
        })
    }

    function loaded() {
        setSmallLoader(false)
    }

    function changeLab(event: EventTarget, row: PreLeafDisplayFlat) {
        wrapLoader(context, LeafController.updateLab({
            testLeafDataId: row.testLeafDataId,
            lab: (event as HTMLInputElement).value
        }), _ => {
            leafTableRef.current?.refresh()
        })
    }

    function getImage(id: number) {
        setImageData('')

        wrapLoader(context, LeafController.getApiData({id}), resp => {
            const photoUrl = resp.mapped?.photoUrl
            setShowImage(true)
            if (!photoUrl) {
                setImageData('')
                return
            }
            setSmallLoader(true)
            setImageData(photoUrl)
        })
    }

    function getJson(id: number) {
        wrapLoader(context, LeafController.getApiData({id}), resp => {
            setShowJson(true)
            setJsonData(resp)
        })
    }
    function archiveWarning(id: number, archive: boolean) {
        confirmationWarningState.show('Are you sure you want to archive this row?', id)
        setArchiveState(archive)
        setRowId(id)
    }

    function restoreWarning(id: number, archive: boolean) {
        setRowId(id)
        setArchiveState(archive)
        restoreWarningState.show("Are you sure you want to restore this row?", id)
    }
    
    
    const col: TableColumn<PreLeafDisplayFlat>[] = [
        {
            header: 'Lab',
            row: (item, index) =>
                <select
                    value={item.lab}
                    id="labId"
                    className={
                        (index % 2 === 0) ? 'border-0 bg-white' : '' +
                        (index % 2 !== 0) ? 'border-0 bg-gray-50' : '' +
                        !(index === selected) ? '' : 'text-xs w-24 border-0 bg-primary'
                    }
                    onChange={event => changeLab(event.target, item)}
                >
                    {labs.map(lab => <option key={lab.name} value={lab.name}>{lab.name}</option>)}
                </select>
        },
        {
            header: 'Sample',
            row: (item, index) => <span>{item.sampleNumber}</span>
        },
        {
            header: <>
                Client (Farm)
                <div className="text-xs whitespace-nowrap text-gray-500">
                    Block
                </div>
            </>,
            row: (item, index) =>
                <>
                    {item.clientName} ({item.farmName})
                    <div className="text-xs whitespace-nowrap text-gray-500">
                        {item.blockNumber}
                    </div>
                </>
        },
        {
            header: 'Crop',
            row: (item, index) => item.crop
        },
        {
            header: <>
                Agent
                <div className="text-xs whitespace-nowrap text-gray-500">
                    Agronomist
                </div>
            </>,
            row: (item, index) => <>
                {item.agent}
                <div className="text-xs whitespace-nowrap text-gray-500">
                    {item.agronomist}
                </div>
            </>
        },
        {
            header: <>
                Date Synced
                <div className="text-xs whitespace-nowrap text-gray-500">
                    Date Captured
                </div>
            </>,
            row: (item, index) => <>
                {dateFormat(item.inserted)}
                <div className="text-xs text-gray-500">
                    {dateFormat(item.appAdded)}
                </div>
            </>
        },

    ]

    function syncImage(id: number) {
        const hideLoader = context.showLoader()
        LeafController.syncFirebaseCropPhotoAndLocation({id})
            .then(() => {
                context.showSnack(<Success title={"Sync done"}/>)
            })
            .finally(hideLoader)
    }

    function extendedCol(): TableColumn<PreLeafDisplayFlat>[] {
        if (props.notCompleted) {
            return col.concat(
                {
                    header: <div className='text-right'>Actions</div>,
                    row: (item) => <div className='text-right'>
                        <button className="mr-1 btn-primary btn-sm" onClick={_ => getImage(item.id)}>image</button>
                        <button className="mr-1 btn-primary btn-sm" onClick={_ => getJson(item.id)}>json</button>
                        <button className="btn-error btn-sm" onClick={_ => archiveWarning(item.id, true)}>x</button>
                    </div>
                })
        }

        return col.concat(
            {
                header: <div className='whitespace-nowrap'>Submitted Id</div>,
                row: (item) => item.submissionJobId
            },
            {
                header: <div className='whitespace-nowrap'>Submitted On</div>,
                row: (item) => dateFormat(item.submittedOn)
            },
            {
                header: 'Actions',
                row: (item) => <>
                    <button className="mr-1 btn-primary btn-sm" onClick={_ => getImage(item.id)}>image</button>
                    <button className="mr-1 btn-primary btn-sm" onClick={_ => getJson(item.id)}>json</button>
                    <button className="mr-1 btn-sm bg-red-500" onClick={_ => syncImage(item.id)}>sync</button>
                    {props.archived ?
                        <button className="btn-primary btn-sm" onClick={_ => restoreWarning(item.id, false)}>restore
                        </button> : null}
                </>
            }
        )
    }

    function callerComp() {
        if (props.notCompleted)
            return LeafController.notCompleted
        if (props.archived)
            return LeafController.archived
        return LeafController.completed;
    }
    

    return (
        <div className="bg-white p-2 shadow">
            {props.results
                ? <PagedSearchTable componentRef={resultTableRef} call={LeafController.getLeafResults}
                                      downloadExcelCall={LeafController.pagedExcel}
                                      columns={resultCol} keyExtractor={i => i.id}/>
                : <PagedSearchTable componentRef={leafTableRef} call={callerComp()} columns={extendedCol()}
                                    keyExtractor={i => i.id}/>}


            <Dialog title="Image" show={showImage} setShow={setShowImage}>
                {smallLoader ? <div className="absolute inset-0 bg-overlay-200 flex justify-center items-center">
                    <img src='/images/loader.gif' alt=''/>
                </div> : null}
                <img onLoad={loaded} className="w-96" src={imageData} alt="image"/>
            </Dialog>

            <WarningPopup state={confirmationWarningState} onYes={() => archive(rowId, archiveState)}/>
            <WarningPopup state={restoreWarningState} onYes={() => restore(rowId, archiveState)}/>

            <Dialog show={showJson} setShow={setShowJson} title="Json" body={<>
                <div className="flex text-left">
                    <div className="w-1/2 px-2">
                        <p className="text-xl font-bold">Original</p>
                        <pre className="whitespace-pre-wrap break-words text-xs">{JSON.stringify(jsonData?.original)}</pre>
                    </div>
                    <div className="w-1/2 px-2">
                        <p className="text-xl font-bold">Mapped</p>
                        <pre className="whitespace-pre-wrap text-xs">{JSON.stringify(jsonData?.mapped)}</pre>
                    </div>
                </div>
                <div className="px-2">
                    <p className="text-xl font-bold">Test Leaf Data</p>
                    <pre className="whitespace-pre-wrap text-xs">{JSON.stringify(jsonData?.testLeafData)}</pre>
                </div>
            </>}/>
        </div>
    )
}

export default LeafsTab;