import React, {useRef} from "react";
import FarmersSampleController from "../../controllers/FarmersSampleController";
import {SubmissionPrepareBase} from "../submission/SubmissionPrepareBase";
import PrepareSubmissionFarmer from "../../controllers/PrepareSubmissionFarmer";
import SubmissionTable, {SubmissionPrepareFunctions} from "./SubmissionTable";
import SubmissionDataResponse from "../../controllers/SubmissionDataResponse";
import {arrayUpdate} from "../../immutableState";
import Lab from "../../controllers/Lab";

const csvPopulateFarmer = {
    "Sample Number": (value: string, row: PrepareSubmissionFarmer) => {
        row.sampleNumber = value;
    },
    // todo add rest of the fields
};

export type FarmerRow = PrepareSubmissionFarmer & { selected?: boolean };

export default function SubmissionPrepareFarmer(props: { dataIds: number[]; close: (saved: boolean) => void }) {
    const submitRef = useRef<SubmissionPrepareFunctions>();
    function renderTable(submissionData: SubmissionDataResponse<FarmerRow>, data: FarmerRow[][], setData: (newData :FarmerRow[][]) => void) {
        return (
            <>
                {data.map((rowArr, index) => (
                    <div key={index}>
                        <SubmissionTable index={index} data={submissionData} leafs={rowArr} submitRef={submitRef} setData={d => {
                                setData(arrayUpdate(data, index, d))
                             // setData(arrayUpdate(d, index, d))
                        }} cultivars={[]}/>
                    </div>
                ))}
            </>
        );
    }

    return (
        <SubmissionPrepareBase
            labs={[Lab.ManualFarmer]}
            testDataIds={props.dataIds}
            close={props.close}
            prepare={FarmersSampleController.prepareSubmission}
            submit={FarmersSampleController.submit}
            renderTable={renderTable}
            csvPopulateMap={csvPopulateFarmer}
        />
    );
}
