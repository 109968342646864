/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type PaginationResponse from "./PaginationResponse";
import type PendingSubmissionQuery from "./PendingSubmissionQuery";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type SubmissionDisplay from "./SubmissionDisplay";
import type Void from "./Void";
import type ManualSubmissionRequest from "./ManualSubmissionRequest";
import type Data from "./Data";
import type IdRequest from "./IdRequest";
import type ManualJobRequest from "./ManualJobRequest";
import type PreReportData from "./PreReportData";
import type SubmissionReportDataLeafResponseApi from "./SubmissionReportDataLeafResponseApi";
import type IdsRequest from "./IdsRequest";
import type MfwSuccess from "./MfwSuccess";
import type File from "./File";
import type ReportDataResultLeaf from "./ReportDataResultLeaf";
import type UpdateReportDataRequestLeaf from "./UpdateReportDataRequestLeaf";
import type SubmissionDataResponse from "./SubmissionDataResponse";
import type PrepareSubmissionLeaf from "./PrepareSubmissionLeaf";
import type DataRequest from "./DataRequest";
import type ManualFileSubmissionRequest from "./ManualFileSubmissionRequest";
import type ReportDataRequest from "./ReportDataRequest";

const urls = {
    exportResults(request: IdRequest): string {
        return queryUrl("/api/submission/exportresults", request);
    },
    questPdf(leafIds: number[]): string {
        return queryUrl("/api/submission/questpdf", {leafIds});
    },
    questImage(submissionId: number, leafId: number, recommendation: string, whatDoesPlantSay: string): string {
        return queryUrl("/api/submission/questimage", {submissionId, leafId, recommendation, whatDoesPlantSay});
    }
}

export default {
    urls,
    paged(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<PendingSubmissionQuery>>> {
        return Axios.post("/api/submission/paged", request, defaultConfig);
    },
    pagedCompleted(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<SubmissionDisplay>>> {
        return Axios.post("/api/submission/pagedcompleted", request, defaultConfig);
    },
    pagedResults(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<SubmissionDisplay>>> {
        return Axios.post("/api/submission/pagedresults", request, defaultConfig);
    },
    pagedWaiting(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<SubmissionDisplay>>> {
        return Axios.post("/api/submission/pagedwaiting", request, defaultConfig);
    },
    getSampleNumbersForSubmission(submissionId: number): Promise<AxiosResponse<string[]>> {
        return Axios.post("/api/submission/getsamplenumbersforsubmission", submissionId, defaultConfig);
    },
    updateManualSubmission(request: ManualSubmissionRequest): Promise<AxiosResponse<Void>> {
        return Axios.post("/api/submission/updatemanualsubmission", request, defaultConfig);
    },
    getResults(request: IdRequest): Promise<AxiosResponse<Data[]>> {
        return Axios.post("/api/submission/getresults", request, defaultConfig);
    },
    manualLabId(request: ManualJobRequest): Promise<AxiosResponse<boolean>> {
        return Axios.post("/api/submission/manuallabid", request, defaultConfig);
    },
    exportResults(request: IdRequest): Promise<AxiosResponse> {
        return Axios.get(queryUrl("/api/submission/exportresults", request), defaultConfig);
    },
    preReport(request: IdsRequest): Promise<AxiosResponse<PreReportData<SubmissionReportDataLeafResponseApi>>> {
        return Axios.post("/api/submission/prereport", request, defaultConfig);
    },
    postToMfw(request: IdRequest): Promise<AxiosResponse<MfwSuccess>> {
        return Axios.post("/api/submission/posttomfw", request, defaultConfig);
    },
    getDocs(request: IdRequest): Promise<AxiosResponse<File[]>> {
        return Axios.post("/api/submission/getdocs", request, defaultConfig);
    },
    print(request: IdRequest): Promise<AxiosResponse<string>> {
        return Axios.post("/api/submission/print", request, defaultConfig);
    },
    updateReportData(reports: UpdateReportDataRequestLeaf[]): Promise<AxiosResponse<ReportDataResultLeaf[]>> {
        return Axios.post("/api/submission/updatereportdata", reports, defaultConfig);
    },
    prepareSubmission(request: DataRequest): Promise<AxiosResponse<SubmissionDataResponse<PrepareSubmissionLeaf>>> {
        return Axios.post("/api/submission/preparesubmission", request, defaultConfig);
    },
    questPdf(leafIds: number[]): Promise<AxiosResponse> {
        return Axios.get(queryUrl("/api/submission/questpdf", {leafIds}), defaultConfig);
    },
    uploadManualSubmissionFiles(request: ManualFileSubmissionRequest): Promise<AxiosResponse<Void>> {
        return Axios.post("/api/submission/uploadmanualsubmissionfiles", toFormData(request), defaultFormConfig);
    },
    questImage(submissionId: number, leafId: number, recommendation: string, whatDoesPlantSay: string): Promise<AxiosResponse> {
        return Axios.get(queryUrl("/api/submission/questimage", {submissionId, leafId, recommendation, whatDoesPlantSay}), defaultConfig);
    },
    delete(request: IdRequest): Promise<AxiosResponse<boolean>> {
        return Axios.post("/api/submission/delete", request, defaultConfig);
    },
    submit(request: ReportDataRequest<PrepareSubmissionLeaf>): Promise<AxiosResponse<ReportDataRequest<PrepareSubmissionLeaf>>> {
        return Axios.post("/api/submission/submit", request, defaultConfig);
    },
    archiveLeafs(request: IdsRequest): Promise<AxiosResponse> {
        return Axios.post("/api/submission/archiveleafs", request, defaultConfig);
    },
    archive(request: IdRequest): Promise<AxiosResponse> {
        return Axios.post("/api/submission/archive", request, defaultConfig);
    }
}
